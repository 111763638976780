import React, { useState } from 'react';
import dayjs from "dayjs";
import { Drawer, Select, Divider , Space,Button} from 'antd';
import { useTranslation } from 'react-i18next';

const WorkForceDrawer = ({ storedOperation, data, missionSlots, openDrawer, handlerMethod , onWorkForceSubmit }) => {
    const { t } = useTranslation();

    const numbers = Array.from({ length: 101 }, (_, i) => ({ label: i.toString(), value: i }));

    const [selectValues, setSelectValues] = useState({
        roles: {},
        positions: {},
        edays: {},
        timeSlots: {}
    });

    const onSelectNumber = (field, value, locIdx, roleIdx,posIdxx=null, edayGrpIdxx=null,edayIdxx=null,tsIdxx=null) => {

        const newSelectValues = { ...selectValues, [field]: { ...(selectValues[field] || {}) } };
    
        let key = `${locIdx}-${roleIdx}`;
        if (posIdxx!== null) key +=  `-${posIdxx}`;
        if (edayGrpIdxx!== null) key +=  `-${edayGrpIdxx}`;
        if (edayIdxx!== null) key +=  `-${edayIdxx}`;
        if (tsIdxx!== null) key +=  `-${tsIdxx}`;

        newSelectValues[field][key] = value;
        
        switch(field){
            case 'roles' :
                data.positions.forEach((_, posIdx) => {
                    newSelectValues.positions[`${locIdx}-${roleIdx}-${posIdx}`] = value;
                    data.edays.forEach((edayGroup, edayGrpIdx) => {
                        edayGroup.forEach((_, edayIdx) => {
                            newSelectValues.edays[`${locIdx}-${roleIdx}-${posIdx}-${edayGrpIdx}-${edayIdx}`] = value;
                            data.timeSlots.forEach((_, tsIdx) => {                        
                                newSelectValues.timeSlots[`${locIdx}-${roleIdx}-${posIdx}-${edayGrpIdx}-${edayIdx}-${tsIdx}`] = value;
                            });
                        });
                    });
                });                
                break;
            case 'positions' :
                newSelectValues.positions[`${locIdx}-${roleIdx}-${posIdxx}`] = value;
                data.edays.forEach((edayGroup, edayGrpIdx) => {
                    edayGroup.forEach((_, edayIdx) => {
                        newSelectValues.edays[`${locIdx}-${roleIdx}-${posIdxx}-${edayGrpIdx}-${edayIdx}`] = value;
                        data.timeSlots.forEach((_, tsIdx) => {                        
                            newSelectValues.timeSlots[`${locIdx}-${roleIdx}-${posIdxx}-${edayGrpIdx}-${edayIdx}-${tsIdx}`] = value;
                        });
                    });
                });                        
                break;    
            case 'edays' :
                newSelectValues.edays[`${locIdx}-${roleIdx}-${posIdxx}-${edayGrpIdxx}-${edayIdxx}`] = value;
                data.timeSlots.forEach((_, tsIdx) => {                        
                    newSelectValues.timeSlots[`${locIdx}-${roleIdx}-${posIdxx}-${edayGrpIdxx}-${edayIdxx}-${tsIdx}`] = value;
                });          
                break;                              
            default:
        }

        setSelectValues(newSelectValues);
    };
    
    const onSubmit = (action)=>{
        let cleanData = [];
        if (action === 'add') cleanData = [...missionSlots];
        if (selectValues.timeSlots && Object.keys(selectValues.timeSlots).length) {
            for(const selectValue in selectValues.timeSlots){
                if (selectValues.timeSlots[selectValue] > 0) {
                    const [locIdx,roleIdx,posIdx,edayGrpIdx,edayIdx,tsIdx] = selectValue.split('-');                    
                    if (data.edays[edayGrpIdx] && data.edays[edayGrpIdx][edayIdx]) {
                        cleanData.push({
                            location : data.locations[locIdx],
                            role : data.roles[roleIdx],
                            position : data.positions[posIdx],
                            edayGrpKey : edayGrpIdx,
                            edayGrp : data.edays[edayGrpIdx],
                            eday : data.edays[edayGrpIdx][edayIdx],
                            timeSlot : data.timeSlots[tsIdx],
                            workForce : selectValues.timeSlots[selectValue]
                        });
                    }              
                }
            }
        }
        handlerMethod(false);        
        return onWorkForceSubmit(cleanData);
    };

    return (
        <Drawer
            title={
                <div className="h3b grey-dark message-drawer-title ">
                    <div style={{ marginLeft: '16px' }}>{t('operation.steps.workforce')}</div>
                </div>
            }
            extra={
                <Space>
                  <Button className="button-small apply" type="primary" onClick={()=>onSubmit('add')}>{t('buttons.addMission')}</Button>
                  {!storedOperation && !storedOperation._id &&
                    <Button className="button-small apply" type="primary" onClick={()=>onSubmit('create')}>{t('buttons.createMission')}</Button>
                  }
                </Space>
            }            
            placement="right"
            width={500}
            onClose={() => handlerMethod(false)}
            open={openDrawer}
            className=''
        >
            {data.locations.map((location, locIdx) => (
                <div key={locIdx.toString()} className="workforce-location" >
                    <div className='h2 uppercase'>{location.name}, {location.address}, {location.city}</div>
                    <Divider className="divider-full" style={{ margin: '10px 0' }} />
                    {data.roles.map((role, roleIdx) => (
                        <div key={roleIdx.toString()} className="workforce-row" >
                            <div className='row-spread'>
                                <span className='h3 uppercase bold'>Role / {role.name}  -  {role.price}/{role.cost}{role.currency}</span>
                                <Select
                                    placeholder="0"
                                    defaultValue={0}
                                    value={selectValues.roles?.[`${locIdx}-${roleIdx}`]}
                                    onChange={(value) => onSelectNumber('roles', value, locIdx, roleIdx)}
                                    options={numbers}
                                    className="number-select accented"
                                />
                            </div>
                            {data.positions.map((position, posIdx) => (
                                <div key={posIdx.toString()} className="workforce-row" >
                                    <div className='row-spread'>
                                        <span className='capitalize'>Poste / {position}</span>
                                        <Select
                                            placeholder="0"
                                            defaultValue={0}
                                            value={selectValues.positions?.[`${locIdx}-${roleIdx}-${posIdx}`]}
                                            onChange={(value) => onSelectNumber('positions', value, locIdx, roleIdx, posIdx)}
                                            options={numbers}
                                            className="number-select accented"
                                        />
                                    </div>
                                    {data.edays.map((edayGroup, edayGrpIdx) => (
                                        edayGroup.map((eday, edayIdx) => (
                                            <div key={edayIdx.toString()} className="workforce-row" >
                                                <div className='row-spread '>
                                                    <span className='h5 workforce-tabbed-small'>{dayjs(eday, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
                                                    <Select
                                                        placeholder="0"
                                                        defaultValue={0}
                                                        value={selectValues.edays?.[`${locIdx}-${roleIdx}-${posIdx}-${edayGrpIdx}-${edayIdx}`]}
                                                        onChange={(value) => onSelectNumber('edays', value, locIdx, roleIdx, posIdx, edayGrpIdx, edayIdx)}
                                                        options={numbers}
                                                        className="number-select accented"
                                                    />
                                                </div>
                                                {data.timeSlots.map((timeSlot, tsIdx) => (
                                                    <div key={tsIdx.toString()} className="workforce-row grey-bg" >
                                                        <div className='row-spread'>
                                                            <span className='workforce-tabbed-large h5'>{dayjs(timeSlot.stime, 'HH:mm').format("HH:mm")} à {dayjs(timeSlot.etime, 'HH:mm').format("HH:mm")}</span>
                                                            <Select
                                                                placeholder="0"
                                                                defaultValue={0}
                                                                value={selectValues.timeSlots?.[`${locIdx}-${roleIdx}-${posIdx}-${edayGrpIdx}-${edayIdx}-${tsIdx}`]}
                                                                onChange={(value) => onSelectNumber('timeSlots', value, locIdx, roleIdx, posIdx, edayGrpIdx, edayIdx, tsIdx)}
                                                                options={numbers}
                                                                className="number-select accented"
                                                            />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    ))}
                                </div>
                            ))}
                            <Divider className="" style={{ margin: '10px 0' }} />
                        </div>
                    ))}
                </div>
            ))}
        </Drawer>
    );
};

export default WorkForceDrawer;
