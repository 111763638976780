import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { saveUser } from '../../features/user/userSlice';
import { EditUserProps } from "../../services/User"
import { ShowFile } from "../../services/File"

const mediaWidth = 200;

const MediaCarousel = ({ user, nbMedias }) => {       
    
    const carrouselWidth = mediaWidth * nbMedias;

    const medias = user && user.medias ? user.medias.filter((ele)=>{return ele.section === 'media'}) : [];
    const othersMedias = user && user.medias ? user.medias.filter((ele)=>{return ele.section !== 'media'}) : [];

    // Get original stored selectedMedias
    const originalSelectedMedias = [];
    if (medias && medias.length){
        let idx=0;
        for (const media of medias) {
            if (media.selected) originalSelectedMedias.push(idx);
            idx++;
        }
    }

    const { t } = useTranslation();    
    const dispatch = useDispatch();
    const [selectedMedias, setSelectedMedias] = useState(originalSelectedMedias);
    const stripRef = useRef(null);

    const checkMedia = async (index) => {

        let updatedMedias = [];
        if (medias && medias.length) {
            updatedMedias = medias.map((media, idx) => {
                if (idx === index) {
                    return {
                        ...media,
                        selected: media.selected ? false : true
                    };
                }
                return media;
            });        
        }
        
        const userUpdated = await EditUserProps(user, { medias : [...othersMedias, ...updatedMedias] });
        if (userUpdated && userUpdated._id) {
            dispatch(saveUser(userUpdated));
            setSelectedMedias(prevSelectedMedias => {
                if (prevSelectedMedias.includes(index)) {
                    return prevSelectedMedias.filter(idx => idx !== index);
                } else {
                    return [...prevSelectedMedias, index];
                }
            });
        }

    };
    
    const openMedia = async (index) => {
        const selectedFile = medias[index];        
        if (selectedFile) {
            return await ShowFile('media',selectedFile);
        } else {
            console.error('File not found at index', index);
        }
    }

    const scrollMedia = (scrollOffset) => {
        stripRef.current.scrollBy({
          left: scrollOffset,
          behavior: 'smooth'
        });
    };
    
    if (user && user.files) {
        return (
            <div className={ user.files.length > nbMedias ? "media-carousel-container" : "media-carousel-container alt-nb-media"}>
               { user.files.length > nbMedias && <div className="arrow-button" onClick={() => scrollMedia(-mediaWidth)}><LeftOutlined/></div> }
                <div className='media-carousel' ref={stripRef} style={{width:( carrouselWidth )}}>                    
                    { user.files.map((file,index) => (
                        <div key={'div-'+(index+1).toString()} className='media-block'>
                            <div onClick={()=>openMedia(index)}>{t('buttons.enlarge')}</div>
                            <img 
                                title={t('media.clickPdf')}
                                onClick={()=>{checkMedia(index)}}
                                alt="media" 
                                src={file} 
                                style={{ width: mediaWidth + 'px' }} 
                                className={ 
                                    selectedMedias.includes(index) ? "media-detail-user media-selected img-bw" : "media-detail-user img-bw"
                                }
                            />
                        </div>
                    ))}                    
                </div> 
                { user.files.length > nbMedias &&  <div className="arrow-button" onClick={() => scrollMedia(mediaWidth)}><RightOutlined/></div> }
            </div>
        );
    } else {
        return (
            <div className='media-carousel'>
                <img alt="media" src={'/img/placeholder-media.jpg'}  style={{width:mediaWidth+'px'}} className="media-detail-user"/>
            </div>
        );
    }
};

export default  MediaCarousel ;