import { Post, ApiRoutes } from "./Api"
import { MediaUrl } from "./File"

export const MetricsDashboard = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.dashboardMetrics, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Dashboard resultList',err);
    }
};

export const MsgDashboard = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.dashboardMsg, data);
        if (res && res.success && res.value) { 
            
            if (res.value && res.value.length) {
                for (let msg of res.value ) {
                    if (!msg._user.avatar) msg._user.avatar = [];
                    if (msg._user.medias) {
                        msg._user.avatar = await MediaUrl(msg._user.medias,'avatar','small');                        
                    }
                }
            }               

            resultList = res.value;           
        }
        return resultList;
    } catch (err) {
      console.error('Dashboard resultList',err);
    }
};

export const UsersDashboard = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.dashboardUsers, data);
        if (res && res.success && res.value) {  
            if (res.value && res.value.length) {
                for (let user of res.value ) {
                    if (!user.avatar) user.avatar = [];
                    if (user.medias) {
                        user.avatar = await MediaUrl(user.medias,'avatar','small');                        
                    }
                }
            }              
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Dashboard resultList',err);
    }
};


export const MissionsDashboard = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.dashboardMissions, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Dashboard resultList',err);
    }
};


export const ToDoDashboard = async (data) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.dashboardTodo, data);
        if (res && res.success && res.value) {    
            resultList = res.value;            
        }
        return resultList;
    } catch (err) {
      console.error('Dashboard resultList',err);
    }
};

// dashboardMetrics: '/admin/dashboard/metrics',
// dashboardMsg: '/admin/dashboard/messages',
// dashboardUsers: '/admin/dashboard/users',
// dashboardMissions: '/admin/dashboard/missions',
// dashboardTodo: '/admin/dashboard/todos',