import React, { useEffect, useState } from 'react';
import { TeamOutlined} from '@ant-design/icons';

import { useSelector,useDispatch } from 'react-redux';
import { useNotification } from '../../../hooks/NotificationProvider';

import { saveLists } from '../../../features/lists/listsSlice';
import { saveOperation } from '../../../features/operation/operationSlice';
import { EditOpProps , GetOp} from '../../../services/Operation';
import { DemandMission } from '../../../services/Mission';
import { getMissionSlotsDateRange, FormatMissionSlotsByRoles, updateList } from "../../../utils/miscHelpers"

import OperationGeneral from "./OperationGeneral"
import MissionSlotsByRole from '../elements/MissionSlotsByRole';
import RoleDrawer from '../elements/RoleDrawer'

const RoleTab =  ({
            section, 
            staffList, 
            openGeneral,
            setOpenGeneral,
            bmgFilters, 
            onPopChange, 
            onLabelUpdated, 
            storedOperation,
            showModal,
        }) => {

    const { openNotification } = useNotification();
    
    const dispatch = useDispatch();

    //const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [roles, setRoles] = useState({});

    const showDrawer = () =>{
        setOpenDrawer(true);
    };
    
    const closeDrawer =  () =>{
        setOpenDrawer(false);
    };

    const sendDemandToStaff = async (staffs,type, selectedSlots) => {
        closeDrawer();

        if (!staffs || !staffs.length) return false;
        if (type === null || type === undefined) type = 3;
        if (type === 10)  return true;

        const choosenStaff = staffs.map((staff)=>{
            const missionSlots = [];
            for(let missionSlot of staff.missionSlots){
                missionSlot = {...missionSlot};
                for(const selectedSlot of selectedSlots){
                    const [staffId, _missionSlot] = selectedSlot.split('_');                    
                    if (staffId === staff._id && _missionSlot === missionSlot._id) {
                        missionSlot.status = type;
                    } 
                }   
                missionSlots.push(missionSlot);            
            }
            return {
                _id: staff._id,                
                missionSlots: missionSlots
            };
        });
        
        if (choosenStaff.length && storedOperation._id) {
            const entryDemanded = await DemandMission(storedOperation._id,choosenStaff,type);            
            if (entryDemanded) {
                openNotification('success',"Demande Envoyée");                   
                const entryUpdated = await GetOp(storedOperation._id);                
                const updatedList = updateList(storedList,entryUpdated) ;
                dispatch(saveLists({section:section,data:updatedList}));
                dispatch(saveOperation(entryUpdated));
            } else {
                openNotification('error',"Un problème est survenu lors de l'envoi de la demande");     
            }  
        }
        return true;        
    };

    // useEffect(() => {
    //     const fetchData = async () => {
    //       try {
    //         let listStaff = [];
    //         if (storedOperation && storedOperation.missionSlots && storedOperation.missionSlots.length) {                
    //             for (const missionSlot of storedOperation.missionSlots) {
    //                 if (missionSlot.users && missionSlot.users.length) {
    //                     for(const userId of missionSlot.users){
    //                         if (!listStaff.includes(userId)) listStaff.push(userId);
    //                     }
    //                 };
    //             }
    //         }

    //         if (listStaff.length) {
    //             let sortItems= {};
    //             sortItems.sort = {lastName:1};            
        
    //             // Get user list
    //             const {resultList} = await ListUsers({filters:{...{ users : listStaff }, ...sortItems}});

    //             // Add Mission Slots to User List
    //             if (storedOperation && storedOperation.missionSlots && resultList && resultList.length) {
    //               for(let user of resultList){
    //                 user = LocalUpdateUserMission(user,storedOperation);
    //               }
    //             }
    //             setStaffList(resultList);
    //         }
      
    //         return;
    //       } catch (error) {
    //         console.error('Error fetching user list:', error);
    //       }
    //     };
    //     fetchData();    
    // }, []); 
    
    useEffect(() => {
        setRoles(FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : []));
    }, [storedOperation]); 

    const saveOpProps = async (newMissions,origin='',applyLocationToAll=false,data=null)=>{
       
        const { sdate, edate } = getMissionSlotsDateRange(newMissions);
        const sortedMissions = newMissions.sort((a, b) => {            
            return new Date(a.eday) - new Date(b.eday);
        });

        const props = {
            sdate, 
            edate,
            missionSlots : sortedMissions,
            applyLocationToAll:applyLocationToAll,
        };
        if (data && data.roles) props.roles = data.roles;
        if (data && data.roleKey !== undefined && data.roleKey !== null) props.roleKey = data.roleKey;
        
        const entryUpdated = await EditOpProps(storedOperation._id,props,origin);
        if (entryUpdated && entryUpdated._id) {
            openNotification('success',"Enregistrée");     
            const updatedList = updateList(storedList,entryUpdated) ;
            dispatch(saveLists({section:section,data:updatedList}));
            dispatch(saveOperation(entryUpdated));  
        } else {
            openNotification('error',"Un problème est survenu lors de la sauvegarde");     
        }  
        return true;
    };

    const changeEntry = async (entryType, e,index,missionId) =>{        
        switch(entryType){
            case 'missionWorkForce' :              
                let newMissions = storedOperation.missionSlots.map(ele => ({ ...ele }));
                for(let mission of newMissions){
                    if (missionId && mission._id === missionId) mission.workForce = e;
                }
                return saveOpProps(newMissions);
            default:  
        }         
    };    

    const deleteEntry = async (entryType, idx,missionId) =>{
        if (window.confirm('Êtes-vous sur de vouloir effacer cette ligne ?')){
            switch(entryType){
                case 'mission' :
                    const cleanMissions = [...storedOperation.missionSlots];
                    const newMissions = cleanMissions.filter((ele,index)=> ele._id !== missionId);
                    return saveOpProps(newMissions);
                default:           
            }
        };
    };
    
    const onSubmitMissionSlot = (missionProps) =>{            

        let newMissions = storedOperation.missionSlots.map(mission =>{
            let cleanMission = mission._id === missionProps.data._id ? { ...missionProps.data } : {...mission};            
            if (missionProps.applyLocationToAll && missionProps.data.location.key === mission.location.key) cleanMission.location = missionProps.data.location;
            return cleanMission;
        });        
        return saveOpProps(newMissions,'missionSlot',missionProps.applyLocationToAll);
    };

    const onSubmitRole = (roleProps) =>{        
        const newRole = {
            name : roleProps.name,
            cost : roleProps.cost,
            price : roleProps.price,
        } 
        let newMissions = storedOperation.missionSlots.map(mission =>{
            let cleanMission = {...mission}; 
            if (roleProps.key === cleanMission.role.key) cleanMission.role = {...cleanMission.role, ...newRole};
            return cleanMission;
        });
        let newRoles = storedOperation.roles.map((role)=>{
            let cleanRole = {...role}; 
            if (roleProps.key === role.key) cleanRole = {...cleanRole, ...newRole};
            return cleanRole;
        });
        return saveOpProps(newMissions,'roleForm',null,{roleKey : roleProps.key, roles : newRoles});
    };

    return(
        <div className="tab-content">
            <OperationGeneral
                section={section}
                bmgFilters={bmgFilters}
                openGeneral={openGeneral}
                setOpenGeneral={setOpenGeneral}
                staffList={staffList}
            />
            <div className={'tab-block '+ (openGeneral ? '' : 'folded')} style={{
                    display:'flex',
                    marginTop: "33px",
                    marginLeft: "2%",     
                    marginRight: "2%",     
                    // width: "80%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start"}}
                >
                <div className='flex' style={{ marginBottom:'16px', width:'100%',alignItems: "center",justifyContent: "space-between"}}>
                    <div className='h2'>{roles && Object.keys(roles) ? Object.keys(roles).length : 0} Rôles / {storedOperation.missionSlots ? storedOperation.missionSlots.length : 0} Missions Planifiées</div>
                    <div style={{alignItems: "center",}} className='flex link' title='Gérer le Staff' onClick={showModal}>
                        <span className='h2s' style={{fontSize:'12px',marginRight:'6px'}}>Staff Affécté</span>
                        <TeamOutlined className='h2'/>
                    </div>  
                    <div style={{alignItems: "center",}} className='flex link' title='Gérer le Staff' onClick={showDrawer}>
                        <span className='h2s' style={{fontSize:'12px',marginRight:'6px'}}>Booker le Staff</span>
                        <TeamOutlined className='h2'/>
                    </div>                       
                </div>
                <MissionSlotsByRole
                    section={section}
                    roles={roles}
                    staffList={staffList}
                    storedOperation={storedOperation}
                    changeEntry={changeEntry}
                    deleteEntry={deleteEntry}
                    onSubmitMissionSlot={onSubmitMissionSlot}     
                    onSubmitRole={onSubmitRole}      
                />
            </div>
            <RoleDrawer 
                section={section}
                drawerLoading={true}
                openDrawer={openDrawer}
                handlerMethod={sendDemandToStaff} 
                staffList={staffList} 
                storedOperation={storedOperation}
                onPopChange={onPopChange}    
                onLabelUpdated={onLabelUpdated}                     
            />        
        </div>
    )
};

export default RoleTab;