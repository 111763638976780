import React from 'react';
import dayjs from "dayjs";
import { paymentSpecials, languageLevels, onGoingStudies ,levelHospitality, diplomas, geographicAvailibilities, Languages, Countries,  CountriesFr } from "./constants";
import { StarFilled } from '@ant-design/icons';
import moment from 'moment';

export const capitalize = (str) => {
    if (!str || !str.length) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
};
export const doS = (arr,type='arr')=>{
    if (type === 'arr') return arr && arr.length && arr.length > 1 ? 's' : '';
    if (type === 'number') return arr > 1 ? 's' : '';
};

export const getAge = (birthday) => {
    const birthDate = new Date(birthday);
    const today = new Date();
    
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    
    // If the current month is before the birth month or if it's the same month but the current day is before the birth day, decrease age by 1
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    
    return !isNaN(age) ? age : '-';
};

export const getBirthday =  (birthday) => {
    return moment(birthday).format('DD/MM/YYYY');
}

export const getOnGoingStudies = (id) =>{
    const obj = onGoingStudies.find((ele)=>{ return ele.id === id});
    return obj ? obj.title : '';
}

export const getLevelHospitality = (id) =>{
    const obj = levelHospitality.find((ele)=>{ return ele.id === id});
    return obj ? obj.title : '';
}

export const getDiplomas = (id) =>{
    const obj = diplomas.find((ele)=>{ return ele.id === id});
    return obj ? obj.title : '';
}

export const getGeographicAvailibilities = (values) =>{
    const geoAvailablities = [];
    if (values && values.length) {
        for (const value of values) {
            const obj = geographicAvailibilities.find((ele)=>{ return ele.id === value});
            if (obj) geoAvailablities.push(obj.title);
        }
    }    
    return geoAvailablities.join(', ');
}

export const getGender =  (genderId) => {
    let gender = ''
    switch(genderId){
        case 1 : 
            gender = "H"
        break;
        case 2 : 
            gender = "F"
        break;
        case 3 : 
            gender = "N"
        break;   
        default:     
    }
    return gender;
}

export const getAddress = (user, direct = null) => {
    let addressParts = [];
    //console.log('getAddress',user);
    if (user.name) addressParts.push(user.name);
    if (user.address) addressParts.push(user.address);
    if (user.zipcode) addressParts.push(user.zipcode);
    if (user.city) addressParts.push(user.city);
    if (user.country) {
        const country = direct ? user.country : getCountry(user.country, 'fr');
        addressParts.push(country ? country : capitalize(user.country));
    }
    return addressParts.join(', ');
};

export const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getCountry = (countryCode,lang) =>{
    if (!countryCode) return '';    
    if (lang === 'fr'){
        return CountriesFr[countryCode];
    }
    const country = Countries.find((country)=>{  return country[1] === countryCode ;});
    return country ? country[0] : '';
};

export const changeArrayToLabelValue = (arr) => {
    const transformedArr = [];
    for (const key in arr) {
        transformedArr.push({ label: arr[key], value: key });
    }
    return transformedArr;
};

export const changeListToLabelValue = (arr,label,value) => {
    const transformedArr = [];
    for (const ele of arr) {
        transformedArr.push({ label: ele[label], value: ele[value] });
    }
    return transformedArr;
};

export const GetIdsFromArray = (arr) =>{
    const ids = [];
    for(const ele of arr){
        ids.push(ele._id);   
    }
    return ids;
};

export const getLanguages = (langArr,output='label') =>{
    let langString = '';
    for(const lang of langArr){
        if (lang) {
            langString += lang.code + ' ';
        }
    }    
    if (langString === '') langString = '-';

    return langString;
};

export const getLanguage = (langCode) =>{
    const lang = Languages.find((ele)=>{ return ele.value === langCode});
    return lang ? lang.label : '';
}
export const getLanguageLevel = (langLevel) =>{
    const lang = languageLevels.find((ele)=>{ return ele.value === langLevel});
    return lang ? lang.label : '';
}
export const updateList = (list,ele) =>{
    const listUpdated = [];
    for (let item of list){
      if (item._id === ele._id) {
        item = ele;        
      }
      listUpdated.push(item);
    }
    return listUpdated;
};

export const deleteFromeList = (list,ele) =>{
    const listUpdated = [];
    for (let item of list){
      if (item._id !== ele._id) {       
        listUpdated.push(item);
      }
    }
    return listUpdated;
};

export  const scoreContent = (score,className) =>{
    const stars = [];
    for (let i = 0; i < score; i++) {
      stars.push(<StarFilled key={i} style={{color: 'var(--accent-color)'}} />);
    }
    return (
      <div className={className} style={{ display:'flex', justifyContent: 'flex-start'}}>
        {stars}
      </div>
    );
};

export const importBmgFilters = async () => {
    try {
        const module = await import(`${process.env.REACT_APP_PUBLIC_API_URL}/bmgFilters.js`);
        return module.BmgFilters;
    } catch (error) {
        console.error('Error loading module:', error);
    }
};

export const sortOnDateField = (array,field) =>{
    return [...array].sort((a, b) => new Date(b[field]) - new Date(a[field]));
}

export const sortOnField = (array,field) =>{
    if (!array || !array.length) return [];
    [...array].sort((a, b) => {
        if (a[field] < b[field]) {
            return -1;
        }
        if (a[field] > b[field]) {
            return 1;
        }
        return 0;
    });

    return array;
}

export const convertListToValueLabel = (dbList) =>{
    const list = [];
    for(const ele of dbList){
        list.push({
            value : ele.id,
            label : ele.title,
        });
    }
    // Sort the list alphabetically by the label property
    list.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });
    return list;
}

export const formatListWithValueLabel = (dbList) =>{
    const userList = [];
    for(const itemEle of dbList){
        const item = itemEle && itemEle._user ? itemEle._user : itemEle;
        if (item && item._id && !userList.find((ele)=>{ return ele.value === item._id}) ) {
            userList.push({
                value : item._id,
                label : item.name,
                color : item.color,
            });
        }
    }
    return userList;
}

export const formatUserListWithValueLabel = (dbList) =>{
    const userList = [];
    for(const itemEle of dbList){
        const item = itemEle && itemEle._user ? itemEle._user : itemEle;
        if (item && item._id && !userList.find((ele)=>{ return ele.value === item._id}) ) {
            userList.push({
                value : item._id,
                label : item.firstName +' '+ item.lastName
            });
        }
    }
    return userList;
}

export const getNotificationCount = (origin, list, record) =>{
    let count = 0;
    if (record && list && list.length) {
      switch(origin){
        case 'message' :
          if (record && record._id){
            const listNotif = list.filter((notif)=>{return notif._id === record._id});
            if (listNotif && listNotif.length) {
              count = listNotif.length;
            }
          }
          break;
        case 'user' :
          if (record._user && record._user._id){
            const listNotif = list.filter((notif)=>{return notif._user === record._user._id});
            if (listNotif && listNotif.length) {
              count = listNotif.length;
            }
          }               
          break;     
        default:
          break;     
      }
    }
    return count;
  };

  export const getMissionsDates = (missions) => {  
  
    if (!missions || missions.length === 0) {
        return { startDate: null, endDate: null };
    }

    let startDate = new Date(missions[0].eday);
    let endDate = new Date(missions[0].eday);

    missions.forEach(mission => {
        let currentDate = new Date(mission.eday);
        if (currentDate < startDate) {
            startDate = currentDate;
        }
        if (currentDate > endDate) {
            endDate = currentDate;
        }
    });

    // Formatting the dates back to strings
    let formattedStartDate = startDate.toISOString().split('T')[0];
    let formattedEndDate = endDate.toISOString().split('T')[0];

    return { startDate: formattedStartDate, endDate: formattedEndDate };
}


const holidays = [
    '2024-01-01', 
    '2024-07-09', 
    '2024-12-25', 
];

export const isSundayOrHoliday = (date) => {
    const day = dayjs(date).day();
    const isSunday = day === 0;

    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    const isHoliday = holidays.includes(formattedDate);

    return isSunday || isHoliday;
}

export const calculateHoursDifference = (starttime, endtime) => {

    const afterMidnightHours = [0, 1, 2, 3, 4, 5, 6];
    const overTimeHours = [23, 0, 1, 2, 3, 4, 5, 6];

    const [startHours, startMinutes] = starttime.split(':').map(Number);
    const [endHours, endMinutes] = endtime.split(':').map(Number);

    const startDate = new Date();
    startDate.setHours(startHours, startMinutes, 0, 0);
        
    let endDate = new Date();
    if (afterMidnightHours.includes(endHours)) {
        endDate.setDate(endDate.getDate() + 1);
    }
    endDate.setHours(endHours, endMinutes, 0, 0);

    // Calculate the total difference in milliseconds
    const diffInMs = endDate - startDate;
    const totalHrs = diffInMs / (1000 * 60 * 60);  // convert milliseconds to hours

    // Convert for clean reading e.g., 7h15m
    const hours = Math.floor(totalHrs);
    const minutes = Math.round((totalHrs - hours) * 60);

    let cleanTotalHrs = `${hours}h`;
    if (minutes !== 0) cleanTotalHrs += ` ${minutes}m`;

    // Get all hours and minutes in between
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    let normalTimeInMs = 0;
    let overTimeInMs = 0;

    let currentHour = start;

    while (currentHour.isBefore(end)) {
        const nextHour = currentHour.add(1, 'hour').startOf('hour');
        let segmentEnd = nextHour.isAfter(end) ? end : nextHour;

        let durationInMs = segmentEnd.diff(currentHour);

        if (overTimeHours.includes(currentHour.hour())) {
            overTimeInMs += durationInMs;
        } else {
            normalTimeInMs += durationInMs;
        }

        currentHour = segmentEnd;
    }

    const overTimeHrs = overTimeInMs / (1000 * 60 * 60);  // convert milliseconds to hours
    const normalHrs = normalTimeInMs / (1000 * 60 * 60);  // convert milliseconds to hours

    return {
        totalHrs,
        cleanTotalHrs,
        overTimeHrs,
        normalHrs
    };
};

export const prepareDates = (missionSlot) =>{

    const stime = missionSlot.stime ? missionSlot.stime : missionSlot.timeSlot.stime;
    const etime = missionSlot.etime ? missionSlot.etime : missionSlot.timeSlot.etime;
    
    const startTimeStr = `${missionSlot.eday}T${stime}`;

    let endDay  = missionSlot.eday;
    if (isTimeBetween(etime)) {
        endDay = dayjs(endDay).add(1,'day').format('YYYY-MM-DD');
    }
    const endTimeStr = `${endDay}T${etime}`;

    return {
        startTime : new Date(startTimeStr),
        endTime : new Date(endTimeStr)
    }
};

export const calculateTotals = (price, nbHrsWorkForce,  isHoliDay,  type='normal') =>{
    if (type === 'normal') {        
        return Number(price) * ( isHoliDay ? paymentSpecials.holiday : 1) * nbHrsWorkForce ;
    }
    return Number(price) * ( isHoliDay ? paymentSpecials.holiday : 1) * paymentSpecials.extra * nbHrsWorkForce ;
};

export const FormatUserMissionSlots = (missions,status=null) => {
    if (!missions || !missions.length) return [];

    const groupedMissionSlots = missions.reduce((acc, mission) => {
        const key = mission.location.key;
        if (!acc[key]) {
            acc[key] = {
                location : mission.location,
                slots : [],
            };
        }
        acc[key].slots.push(mission);
        return acc;
    }, []);
    
    // Sort each group by eday and stime
    for (let key in groupedMissionSlots) {
        groupedMissionSlots[key].slots.sort((a, b) => {
            const dateA = new Date(a.eday + 'T' + a.stime);
            const dateB = new Date(b.eday + 'T' + b.stime);
            return dateA - dateB;
        });
    }

    const missionTotal = {
        currency : 'chf',
        price : 0,
        total :0,
        totalNormal :0,
        totalOverTime :0,
        totalHrs : 0,
        totalNormalHrs : 0,
        totalOverTimeHrs : 0,
        extra : paymentSpecials.extra,
        holiday : paymentSpecials.holiday,
        data : [],
    };

    for (let missionLocation of groupedMissionSlots) {
        if (missionLocation && missionLocation.slots && missionLocation.slots.length) {
            for (let i = 0; i < missionLocation.slots.length; i++) {
                let slot = { ...missionLocation.slots[i] };
                let doIt = true;
                if (status && slot.status !== status) {
                    doIt = false;
                }
                if (doIt) {
                    slot.isHoliDay = isSundayOrHoliday(slot.eday);
    
                    let { totalHrs, overTimeHrs, normalHrs } = calculateHoursDifference(slot.stime, slot.etime);
    
                    // Minimum Hours billed
                    if (totalHrs < 4) {
                        let difference = 4 - totalHrs;
                        normalHrs += difference;
                        totalHrs = 4;
                    }
    
                    const normalHrsmissionTotal = calculateTotals(slot.price,normalHrs, slot.isHoliDay,'normal'); // Number(slot.price) * (slot.isHoliDay ? missionTotal.holiday : 1) * normalHrs;
                    const overTimeHrsmissionTotal = calculateTotals(slot.price,overTimeHrs,slot.isHoliDay,'overTime'); //Number(slot.price) * (slot.isHoliDay ? missionTotal.holiday : 1) * missionTotal.extra * overTimeHrs;
                    const fullTotal = normalHrsmissionTotal + overTimeHrsmissionTotal;
    
                    slot.total = fullTotal;
                    slot.totalHrs = totalHrs;
                    slot.totalNormal = normalHrsmissionTotal;
                    slot.totalNormalHrs = normalHrs;
                    slot.totalOverTime = overTimeHrsmissionTotal;
                    slot.totalOverTimeHrs = overTimeHrs;
                    
                    if (slot.currency) missionTotal.currency = slot.currency;
                    missionTotal.price = slot.price;
    
                    missionTotal.total += slot.total;
                    missionTotal.totalHrs += slot.totalHrs;
                    missionTotal.totalNormal += slot.totalNormal;
                    missionTotal.totalNormalHrs += slot.totalNormalHrs;
                    missionTotal.totalOverTime += slot.totalOverTime;
                    missionTotal.totalOverTimeHrs += slot.totalOverTimeHrs;
    
                    // Update the original slot in the array
                    missionLocation.slots[i] = slot;
                }
            }
        }
    }
    
    missionTotal.data = groupedMissionSlots;
      

    return missionTotal;
};


export const formatNumber = (number,currency) => {
    // Convert the number to a string
    let numStr = String(number);

    // Split the number into integer part and fractional part (if any)
    let parts = numStr.split('.');
    let integerPart = parts[0];
    let fractionalPart = parts.length > 1 ? '.'+ parts[1].substring(0,2) : '';

    // Reverse the integer part to insert spaces for thousands and millions
    let reversedInteger = integerPart.split('').reverse().join('');

    // Insert spaces after every 3 characters
    let spacedInteger = reversedInteger.match(/\d{1,3}/g).join(' ').split('').reverse().join('');

    if (fractionalPart === '') fractionalPart = ".00";

    // Join the formatted integer part with the fractional part (if any)
    return spacedInteger + fractionalPart + ' ' + currency;

};

export const FormatTotal = (price,currency,decimals=2)=>{
    if (!Number(price)) price = 0;
    if (!currency) currency = 'chf';
    return Number(price).toLocaleString('fr-FR', { 
        style: 'currency', 
        currency: currency,
        minimumFractionDigits: decimals, maximumFractionDigits: decimals
    });
};

export const QuoteTotals = (missions,quoteFees,quoteVat) =>{

    const total = {
        locations : [],
        missions : 0,
        overTime:0,
        overTimeHrs:0,
        holidayTotal:0,
        holidayHrs:0,
        normal:0,
        normalHrs:0,
        hours : 0,
        fees : 0,
        net : 0,
        vat : 0,
        full : 0,
        extra : paymentSpecials.extra,
        holiday : paymentSpecials.holiday
    };
    
    if (missions && missions.length) {
        
        for (const mission of missions) {   
            
            if(mission.location && mission.workForce && Number(mission.workForce) && mission.role?.price && Number(mission.role?.price)){                
                
                let { totalHrs, overTimeHrs, normalHrs} = calculateHoursDifference(mission.timeSlot.stime,mission.timeSlot.etime);
                const isHoliDay = isSundayOrHoliday(mission.eday);
                
                // Minimun Hours billed
                if (totalHrs < 4) {
                    let difference = 4 - totalHrs;
                    normalHrs += difference;
                    totalHrs = 4;
                }
                
                const normalHrsWorkForce   = normalHrs   * Number(mission.workForce);
                const overTimeHrsWorkForce = overTimeHrs * Number(mission.workForce);
                const totalHrsWorkForce    = totalHrs    * Number(mission.workForce);
                let holidayHrsWorkForce    = 0;
               
                const normalHrsmissionTotal   = calculateTotals(mission.role?.cost,normalHrsWorkForce, isHoliDay,'normal'); // Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * normalHrsWorkForce ;
                const overTimeHrsmissionTotal = calculateTotals(mission.role?.cost,overTimeHrsWorkForce,isHoliDay,'overTime');  //Number(mission.role?.cost) * ( isHoliDay ? total.holiday : 1) * total.extra * overTimeHrsWorkForce ;
                const missionTotal            = normalHrsmissionTotal + overTimeHrsmissionTotal;
                let holidayTotal = 0;
                if (isHoliDay){
                    holidayHrsWorkForce = totalHrs    * Number(mission.workForce);
                    holidayTotal = normalHrsmissionTotal + overTimeHrsmissionTotal;
                }                
                if (!total.locations[mission.location.key]) {
                    total.locations[mission.location.key] = {
                        location: mission.location,
                        days:[],
                        roles:[],
                        total:0,
                        totalHrs:0,
                        totalNormal:0,
                        totalNormalHrs:0,
                        totalOverTime:0,
                        totalOverTimeHrs:0,
                        totalHolidayTime:0,
                        totalHolidayHrs:0,                        
                    };
                }
                total.locations[mission.location.key].total += missionTotal;
                total.locations[mission.location.key].totalHrs += totalHrsWorkForce;
                total.locations[mission.location.key].totalNormal += normalHrsmissionTotal;
                total.locations[mission.location.key].totalNormalHrs += normalHrsWorkForce;
                total.locations[mission.location.key].totalOverTime += overTimeHrsmissionTotal;
                total.locations[mission.location.key].totalOverTimeHrs += overTimeHrsWorkForce;
                if (isHoliDay) {
                    total.locations[mission.location.key].totalNormal -= normalHrsmissionTotal;
                    total.locations[mission.location.key].totalNormalHrs -= normalHrsWorkForce;
                    total.locations[mission.location.key].totalHolidayTime += holidayTotal;
                    total.locations[mission.location.key].totalHolidayHrs += holidayHrsWorkForce;                    
                }

                if (!total.locations[mission.location.key].days[mission.edayGrpKey]) {
                    total.locations[mission.location.key].days[mission.edayGrpKey] = {
                        total:0,
                        totalhr:0,
                        totalNormal:0,
                        totalNormalHrs:0,                          
                        totalOverTime:0,
                        totalOverTimeHrs:0,
                        totalHoliday:0,
                        totalHolidayHrs:0,
                        edays:mission.edayGrp
                    };
                }
                total.locations[mission.location.key].days[mission.edayGrpKey].total += missionTotal;
                total.locations[mission.location.key].days[mission.edayGrpKey].totalhr += totalHrsWorkForce;
                total.locations[mission.location.key].days[mission.edayGrpKey].totalNormal += normalHrsmissionTotal;
                total.locations[mission.location.key].days[mission.edayGrpKey].totalNormalHrs += normalHrsWorkForce;
                total.locations[mission.location.key].days[mission.edayGrpKey].totalOverTime += overTimeHrsmissionTotal;
                total.locations[mission.location.key].days[mission.edayGrpKey].totalOverTimeHrs += overTimeHrsmissionTotal;
                if (isHoliDay) {
                    total.locations[mission.location.key].days[mission.edayGrpKey].totalNormal -= normalHrsmissionTotal;
                    total.locations[mission.location.key].days[mission.edayGrpKey].totalNormalHrs -= normalHrsWorkForce;
                    total.locations[mission.location.key].days[mission.edayGrpKey].totalHoliday += holidayTotal;
                    total.locations[mission.location.key].days[mission.edayGrpKey].totalHolidayHrs += holidayHrsWorkForce;
                }


                if (!total.locations[mission.location.key].roles[mission.role.key]) {
                    total.locations[mission.location.key].roles[mission.role.key] = {
                        total:0,
                        totalhr:0,
                        totalNormal:0,
                        totalNormalHrs:0,                        
                        totalOverTime:0,
                        totalOverTimeHrs:0,
                        totalHoliday:0,
                        totalHolidayHrs:0,
                        price:mission.role?.price,
                        cost:mission.role?.cost,
                        name: mission.role?.name,
                        workForce:mission.workForce,
                        employees:0,
                        nbMissions:0,
                        edays:[],
                        isHoliDay:isHoliDay ? isHoliDay : false,
                    };
                }
                
                total.locations[mission.location.key].roles[mission.role.key].employees += mission.workForce;
                total.locations[mission.location.key].roles[mission.role.key].nbMissions++;

                total.locations[mission.location.key].roles[mission.role.key].total += missionTotal;
                total.locations[mission.location.key].roles[mission.role.key].totalhr += totalHrsWorkForce;                
                total.locations[mission.location.key].roles[mission.role.key].totalNormal += normalHrsmissionTotal;
                total.locations[mission.location.key].roles[mission.role.key].totalNormalHrs += normalHrsWorkForce;
                total.locations[mission.location.key].roles[mission.role.key].totalOverTime += overTimeHrsmissionTotal;
                total.locations[mission.location.key].roles[mission.role.key].totalOverTimeHrs += overTimeHrsWorkForce;
                
                if (!total.locations[mission.location.key].roles[mission.role.key].edays.includes(mission.eday)) total.locations[mission.location.key].roles[mission.role.key].edays.push(mission.eday);
                if (isHoliDay) {
                    total.locations[mission.location.key].roles[mission.role.key].isHoliDay = isHoliDay;
                    total.locations[mission.location.key].roles[mission.role.key].totalNormal -= normalHrsmissionTotal;
                total.locations[mission.location.key].roles[mission.role.key].totalNormalHrs -= normalHrsWorkForce;
                    total.locations[mission.location.key].roles[mission.role.key].totalHoliday += holidayTotal;
                    total.locations[mission.location.key].roles[mission.role.key].totalHolidayHrs += holidayHrsWorkForce;     
                }
                
                // for (const eday of mission.edayGrp) {
                //     const formatEday = dayjs(eday).format('YYYY-MM-DD');
                //     if (!total.locations[mission.location.key].roles[mission.role.key].edays.includes(formatEday)) total.locations[mission.location.key].roles[mission.role.key].edays.push(formatEday);
                // }
    
                total.hours += totalHrsWorkForce;
                total.missions += missionTotal;
                total.normalHrs += normalHrsWorkForce;
                total.normal += normalHrsmissionTotal;
                total.overTimeHrs += overTimeHrsWorkForce;
                total.overTime += overTimeHrsmissionTotal;
                if (isHoliDay) {
                    total.normalHrs -= normalHrsWorkForce;
                    total.normal -= normalHrsmissionTotal;
                    total.holidayHrs += holidayHrsWorkForce;
                    total.holidayTotal += holidayTotal;
                }                
            }
        }
    }
    
    if (quoteFees && quoteFees.length) {
        for (const quoteFee of quoteFees) {
            if (quoteFee.price && Number(quoteFee.price)) {
                total.fees += Number(quoteFee.price);
            }
        }
    }

    total.net = total.missions + total.fees;

    if (quoteVat && Number(quoteVat)) {
        total.vat = (total.net *    (Number(quoteVat) / 100)) ;
    } 

    total.full = total.net + total.vat;
    
    return total;
};

export const isTimeBetween = (time) => {
    const format = 'HH:mm';
    const inputTime = dayjs(time, format);
    const startTime = dayjs('00:00', format);
    const endTime = dayjs('06:00', format);
  
    // If inputTime is between startTime and endTime, return true, else return false
    return inputTime.isAfter(startTime) && inputTime.isBefore(endTime);
};


export const getMissionSlotsDateRange = (items) => {
    let startDate = null;
    let endDate = null;
  
    if (items && items.length) {
        items.forEach(item => {
            const { eday, timeSlot: { stime, etime } } = item;
            if (!startDate || eday < startDate.date || (eday === startDate.date && stime < startDate.time)) {
              startDate = { date: eday, time: stime };
            }
            if (!endDate || eday > endDate.date || (eday === endDate.date && etime > endDate.time)) {
              endDate = { date: eday, time: etime };
            }
        });
    }
   
    if (startDate === null) startDate = {date:new Date(), time:'06:00'};
    if (endDate === null) endDate = {date:new Date(),time:'23:00'};
    
    return { 
      sdate : startDate.date+' '+startDate.time, 
      edate : endDate.date+' '+endDate.time, 
    };
  };
  
export const GetMissionStaffStatus = (opId, staff, mission)=>{
    let missionStaffStatus = null;        
    if (staff && staff.missions && staff.missions.length) {
        const staffMission = staff.missions.find((ele)=>ele._operation._id === opId);
        if (staffMission && staffMission.slots && staffMission.slots.length) {
            const slotMission = staffMission.slots.find((ele)=>ele._missionSlot === mission._id);
            if (slotMission && slotMission._missionSlot) {
                return slotMission.status;
            }                
        }
    }
    return missionStaffStatus;
};

  export const LocalUpdateUserMission = (user,storedOperation) =>{
    if (storedOperation && storedOperation.missionSlots) {
      for (const missionSlot of storedOperation.missionSlots) {
        if (missionSlot.users && missionSlot.users.length && missionSlot.users.includes(user._id)) {
          if (!user.missionSlots) user.missionSlots = [];
          user.missionSlots.push(missionSlot);
        }
      }
    }
    // if (storedOperation && storedOperation.missions && storedOperation.missions.length) {
    //     //const mission = storedOperation.missions.find((mission)=>mission._user === user._id);        
    //     //user.operationStatus = mission ? mission.status : null;
    // }

    return user;
  };

  export const FormatStaffByRole = (missionSlots)=>{

    if (!missionSlots || !missionSlots.length) return [];

    let formatedArr = [];
    for(const missionKey in missionSlots){        
        if (missionKey 
            && missionSlots[missionKey] 
            && missionSlots[missionKey].role 
            && missionSlots[missionKey].role.key !== undefined) {
            
            if(!formatedArr[missionSlots[missionKey].role.key]) {
                formatedArr[missionSlots[missionKey].role.key] = {                     
                    name : missionSlots[missionKey].role.name , 
                    price:missionSlots[missionKey].role.price, 
                    cost:missionSlots[missionKey].role.cost, 
                    slots : []
                };
            }
            formatedArr[missionSlots[missionKey].role.key].slots.push({
                eday:missionSlots[missionKey].eday,
                timeSlot:missionSlots[missionKey].timeSlot,
                _missionSlot : missionSlots[missionKey]._id,
            });
        }
    }
    return formatedArr;

  }

  export const FormatMissionSlotsByRoles = (missionSlots) => {
    
    if (!missionSlots || !missionSlots.length) return [];
    
    const roles = {};    
    for(const missionSlot of missionSlots){
        const roleKey = missionSlot.role?.name.replace(/ /g, '_')+'_'+missionSlot.role?.price;
        if (!roles[roleKey]) roles[roleKey] = {...missionSlot.role, missionSlots:[]};
        roles[roleKey].missionSlots.push(missionSlot);
    }
    
    return roles;
  }

  export const FormatMissionSlotsByLocation = (missionSlots) => {
    
    if (!missionSlots || !missionSlots.length) return [];
    
    const locations = [];    
    for(const missionSlot of missionSlots){        
        const locKey = Number(missionSlot.location.key);
        if (!locations[locKey]) locations[locKey] = {...missionSlot.location, missionSlots:[]};
        locations[locKey].missionSlots.push(missionSlot);
    }    
    return locations;
  }
  

  export const ContactedUsersMetris = (mission, storedOperation) =>{

    let cntUserContacted = {
        pending : 0,
        postulate : 0,
        booked:0,
    };
    if (storedOperation.missions && storedOperation.missions.length) {
        for(const storedMission of storedOperation.missions){
            if (storedMission.slots && storedMission.slots.length) {
                const slotMission = storedMission.slots.find((ele)=>{return ele._missionSlot === mission._id });
                if (slotMission && slotMission._missionSlot) {
                    if (slotMission.status === 3) cntUserContacted.pending++;
                    if (slotMission.status === 1) cntUserContacted.booked++;
                    if (slotMission.status === 2) cntUserContacted.postulate++;
                }
            }
        }
    }
    return cntUserContacted;
};

export const CheckMissionSlotStaffStatus = (slot,storedOperation,staffId) => {
    let status = -1;
    if (storedOperation.missions && storedOperation.missions.length) {
        for(const storedMission of storedOperation.missions){
            if (storedMission._user === staffId) {
                if (storedMission.slots && storedMission.slots.length) {
                    const slotMission = storedMission.slots.find((ele)=>{return ele._missionSlot === slot._missionSlot});
                    //console.log('slotMission :>> ', slot, slotMission);
                    if (slotMission && slotMission._missionSlot) {
                        return slotMission.status;
                    }
                }
            }
        }
    }
    return status;
};

const isTimeOverlap = (slotStart, slotEnd, bookedStart, bookedEnd) => {
    return (
      (slotStart.isBefore(bookedEnd) && slotEnd.isAfter(bookedStart)) || // Overlaps partially or fully
      slotStart.isSame(bookedStart) || slotEnd.isSame(bookedEnd) // Exactly same time
    );
  };
  
const canUserBeBooked = (missionSlots, staffMission) => {

    // console.log('missionSlots :>> ', missionSlots);
    //console.log('staffMission :>> ', staffMission._operation.name);

    return missionSlots.map((missionSlot) => {
        const missionStart = dayjs(`${missionSlot.eday} ${missionSlot.stime}`);
        const missionEnd = dayjs(`${missionSlot.eday} ${missionSlot.etime}`);

        // Check if there's any booking overlap for the same day
        const isBooked = staffMission.slots.some((booked) => {
            if (booked.eday === missionSlot.eday) {
                const bookedStart = dayjs(`${booked.eday} ${booked.stime}`);
                const bookedEnd = dayjs(`${booked.eday} ${booked.etime}`);
                return isTimeOverlap(missionStart, missionEnd, bookedStart, bookedEnd);
            }
            return false; // Different day
        });
        
        const ret = {
            ...missionSlot,
            operation : staffMission._operation.name,
            bookable: !isBooked, 
        };
        //console.log('ret',ret);
        return  ret;
    });
};


export const CheckStaffAgenda = (storedOperation,missionSlots,staffMissions) => {

    let staffAgenda = [];
    if (staffMissions && staffMissions.length) {
        for(const staffMission of staffMissions) {            
            if (staffMission._operation._id !== storedOperation._id) {
                const availability = canUserBeBooked(missionSlots, staffMission);                
                if (availability) staffAgenda = [...staffAgenda,...availability];
                // if (availability) staffAgenda.push(availability);
            }
            
        }
    }
    return staffAgenda;
};