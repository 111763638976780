import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Drawer,Input, Checkbox,  Select, Space  } from 'antd';

import { BmgStatus,DemandTypes,MissionBmgStatus, SlotBmgStatus,  DemandTypesDefaultMessages } from "../../../utils/constants"
import { getAge, doS, scoreContent, FormatStaffByRole, CheckMissionSlotStaffStatus } from "../../../utils/miscHelpers"
import MessageForm from '../../../components/message/MessageForm'
import LegendStatus from '../../../components/elements/LegendStatus'

const RoleDrawer = ({
    staffList, 
    section, 
    storedOperation,
    openDrawer,
    handlerMethod,
    onPopChange,
    onLabelUpdated}
) => {    
    
    const [searchTerm, setSearchTerm] = useState('');
    const [userList, setUserList] = useState(staffList);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);
    const [selectedDemand, setSelectedDemand] = useState(null);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [demandDefaultMessage, setDemandDefaultMessage] = useState(null);
    const [fullCheckBox, setFullCheckBox] = useState(false);

    useEffect(() => {    
        setUserList(staffList);
        setSelectedUsers([]);
        setSelectedDemand(null);
        setSelectedSlots([]);
    }, [staffList]);

    const onSubmit = async ()=>{
        handlerMethod(selectedUsers, selectedDemand, selectedSlots);
        setSelectedUsers([]);
        setSelectedDemand(null);
        setSelectedSlots([]);
    };

    const onChangeTerm = (e) =>{        
        setSearchTerm(e.target.value);    
    };
    
    // const onClearTerm = () =>{
    //     setSearchTerm();
    // };

    const onFullCheckbox = () =>{
        let staff = []
        if (!fullCheckBox) staff = staffList;        
        const newSelectedSlots = [];
        if (staff.length) {
            for(const user of staff){
                if (user.missionSlots && user.missionSlots.length) {
                    for(const missionSlot of user.missionSlots){
                        const ref = user._id+'_'+missionSlot._id;
                        newSelectedSlots.push(ref);
                    }                    
                }
            }
        }
        setSelectedUsers(staff);
        setSelectedSlots(newSelectedSlots);
        setFullCheckBox(!fullCheckBox);
    };

    const onCheckboxSlot = (staff,_missionSlot)=>{        
        const ref = staff._id+'_'+_missionSlot;
        let newSelectedSlots = [...selectedSlots];
        if (newSelectedSlots.includes(ref)) {
            newSelectedSlots = newSelectedSlots.filter((ele)=>ele !== ref);

            // Remove from selectedUsers cause no more slots selected for this staff.
            let hasStaffSlot = false;
            for(const newSelectedSlot of newSelectedSlots){
                if (newSelectedSlot.includes(staff._id+'_')) hasStaffSlot = true;
            }
            if (!hasStaffSlot) { 
                let newSelectedUsers = [...selectedUsers];
                newSelectedUsers = newSelectedUsers.filter((ele)=>ele._id !== staff._id);
                setSelectedUsers(newSelectedUsers);
            }

        } else {
            newSelectedSlots.push(ref);
            if (!selectedUsers.includes(staff)) onCheckbox(staff);
        }
        setSelectedSlots(newSelectedSlots);
    }

    const onCheckbox = (staff,staffByRole)=>{
        
        let newSelectedUsers = [...selectedUsers];
        let newSelectedSlots = [...selectedSlots];
        if (newSelectedUsers.includes(staff)) {
            newSelectedUsers = newSelectedUsers.filter((ele)=>ele._id !== staff._id);
            for(const seleSlot of newSelectedSlots){                
                const [staffId , _] = seleSlot.split('_'); // _missionSlot
                if (staffId === staff._id) {
                    newSelectedSlots = newSelectedSlots.filter((ele)=>ele !== seleSlot);
                }
            }
            setSelectedSlots(newSelectedSlots);
        } else {
            newSelectedUsers.push(staff);
            if (staffByRole) {
                for(const role of staffByRole) {
                    if (role && role.slots && role.slots.length) {
                        for (const slot of role.slots) {
                            const slotStaff = staff._id+'_'+slot._missionSlot;
                            if (!newSelectedSlots.includes(slotStaff)) newSelectedSlots.push(slotStaff);
                        }                    
                    }
                }
                setSelectedSlots(newSelectedSlots); 
            }                                       
        }
        setSelectedUsers(newSelectedUsers);
    }
    
    const filteredUsers = userList.filter(user => 
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );    

    const onSelectDemand = (value) =>{
        setSelectedDemand(value);
        const defaultMessage = DemandTypesDefaultMessages.find((ele)=>ele.value === value);
        setDemandDefaultMessage(defaultMessage.label ? defaultMessage.label.replace("%s", storedOperation.name) : '');
    };
    
    return (
        <>
            <Drawer
                title={
                    <div className="h3b grey-dark message-drawer-title ">                                            
                        <div className='capitalize'>{selectedUsers.length} Staff{doS(selectedUsers)} séléctionné{doS(selectedUsers)}</div>
                    </div>
                }
                extra={
                    <Space>
                        <LegendStatus
                            clickable={true}
                            statusFilter={statusFilter}
                            setStatusFilter={setStatusFilter}
                        />
                    </Space>
                }                     
                placement="right"
                width={600}
                onClose={() => handlerMethod(false)}
                open={openDrawer}
                className=''                
            > 
                <div style={{textAlign: "left"}}>
                    <div  style={{marginTop:'6px'}}>
                        <div style={{marginBottom:'10px'}} className='flex'>                            
                            <Select
                                style={{width:'100%'}}
                                placeholder="Type de demande"
                                defaultValue={null}
                                value={selectedDemand}
                                onChange={onSelectDemand}
                                options={DemandTypes}
                                className="custom-select bold"
                            />   
                        </div>  
                        <div style={{marginBottom:'6px'}} className='flex'> 
                            <Checkbox style={{marginRight:'16px'}} className='custom-checkbox' checked={fullCheckBox} onChange={()=> onFullCheckbox()}/>                           
                            <Input placeholder="Rechercher" allowClear onChange={onChangeTerm} />
                        </div>                                              
                        {(filteredUsers && filteredUsers.length !== 0) &&    
                            <div className='step-mission-list' style={{width:'100%',maxHeight:'55vh',overflowY:'auto',justifyContent: "flex-start"}}>
                                {filteredUsers.map((staff,index)=>{                                   
                                    const staffByRole = FormatStaffByRole(staff.missionSlots);
                                    return(
                                        <div key={index.toString()} className="tab-table-row-block" style={{ alignItems: "center",justifyContent: "space-between",padding:'10px 0',width:'100%',borderBottom:'solid 1px var(--accent-thin-color)',margin:'0 0'}}>
                                            <div className="tab-table-row-block">
                                                <Checkbox style={{marginRight:'6px'}} className='custom-checkbox' checked={selectedUsers.find((ele)=> ele._id === staff._id)} onChange={()=> onCheckbox(staff,staffByRole)}/>
                                                <div> 
                                                    <img alt="user" src={staff.avatar ? staff.avatar : '/img/placeholder-avatar.jpg'} className="avatar-small" />                                                    
                                                </div>
                                                <div style={{marginLeft:'6px'}}>
                                                    {(staff.score !== null && staff.score !== 0) && 
                                                        <div className="score-list-user-small">{scoreContent(staff.score,'score-small')}</div>
                                                    }
                                                    <div className="custom-name capitalize bold">{staff.firstName +' '+staff.lastName}</div> 
                                                    <div>
                                                        <span className='tab-table-row-span'>({staff.gender === 1 ? 'H' : 'F'})</span>
                                                        <span className='tab-table-row-span'>{staff.birthday ? getAge(staff.birthday)+' ans' : ''}</span>
                                                        <span className='tab-table-row-span'>{staff.height ? (staff.height/100).toFixed(2)+'m' : ''}</span>
                                                    </div>
                                                    <div style={{marginTop:'16px'}}>
                                                        <span className="capitalize tag" style={{backgroundColor:staff.bmgStatus ? BmgStatus[staff.bmgStatus].color : BmgStatus[0].color}}>{staff.bmgStatus ? BmgStatus[staff.bmgStatus].label : BmgStatus[0].label}</span>
                                                    </div>
                                                    
                                                </div>                                            
                                            </div>
                                            <div>
                                                {MissionBmgStatus[staff.operationStatus] && 
                                                    <span className="capitalize tag" style={{backgroundColor:MissionBmgStatus[staff.operationStatus].color}}>{MissionBmgStatus[staff.operationStatus].label}</span>
                                                }                                                
                                            </div>
                                            <div style={{marginRight:'26px'}}>
                                                {staffByRole  && staffByRole.map((role,idx)=>
                                                    <div key={'role-'+idx.toString()} className='flex' style={{flexDirection: "column",fontSize:'12px',marginBottom:'6px'}}>
                                                        <span className="semibold uppercase">{role.name} - {role.price}/{role.cost}{storedOperation.quoteCurrency}</span>
                                                        {role.slots && role.slots.length && role.slots.map((slot,idx) => {
                                                            const staffSlotStatus = CheckMissionSlotStaffStatus(slot, storedOperation,staff._id);   
                                                            // console.log('staffSlotStatus',staffSlotStatus,SlotBmgStatus[staffSlotStatus]);  
                                                            if (!statusFilter.includes(staffSlotStatus)) {
                                                                return (
                                                                    <div key={'slot-'+idx.toString()} className='flex' style={{ 
                                                                            textDecoration: staffSlotStatus === 0 ? "line-through":"none",
                                                                            alignItems:'center'
                                                                        }}>
                                                                        <div style={{width:'10px',height:'10px', marginRight:'6px', borderRadius:'5px',backgroundColor:SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].color : 'white'}} title={SlotBmgStatus[staffSlotStatus] ? SlotBmgStatus[staffSlotStatus].label : ''}/>
                                                                        
                                                                            <Checkbox style={{marginRight:'6px'}} className='custom-checkbox' checked={selectedSlots.find((ref)=> ref === staff._id+'_'+slot._missionSlot)} onChange={()=> onCheckboxSlot(staff, slot._missionSlot)}/>
                                                                        
                                                                        {/* {staffSlotStatus === 0 &&
                                                                            <div style={{width:"20px"}} />
                                                                        } */}
                                                                        <span style={{marginRight:'10px'}}>{dayjs(slot.eday,'YYYY-MM-DD').format('DD/MM/YY')}</span>
                                                                        <span>{slot.timeSlot?.stime} <span className='uncapitalize'>à</span> {slot.timeSlot?.etime}</span>                                                                       
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }                                                      
                                                         }
                                                        )}  
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        } 
                        <MessageForm 
                            formSize='large'
                            messageUsers={selectedUsers} 
                            defaultMessage={demandDefaultMessage}
                            handlerMethod={onSubmit}
                            checkedData={{
                                selectedSlots,
                                selectedDemand
                            }}
                        />  
                    </div>
                </div>
            </Drawer>
        </>
    );
};
export default RoleDrawer;
