import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
//import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { Table, Pagination } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { TeamOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { useNotification } from '../../../hooks/NotificationProvider';
import { ListJobOffers, DeleteJobOffer } from "../../../services/JobOffer"
import { saveLists } from '../../../features/lists/listsSlice';
import { updateList } from "../../../utils/miscHelpers"
import HeaderBar from "../../../components/layout/HeaderBar";
import LabelForm from "../../../components/label/LabelForm"
import UserListDrawer from "../../../components/joboffer/UserListDrawer";

const section = 'joboffer';

const JobOfferList = () => {
  
  const defaultPageSize = 20;
  
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);  
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openNotification } = useNotification();
  //const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerRecord, setDrawerRecord] = useState({});
  const [listItems, setListItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);
  
  const [sortBy, setSortBy] = useState('cdate');
  const [orderBy, setOrderBy] = useState(-1);

  // VIEW PORT DISPLAY SIZES
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  const fetchData = async () => {
    try {        
      const sort= {};
      sort[sortBy] = orderBy;
      
      // Get list
      const {resultList, total} = await ListJobOffers({page:currentPage,pageSize:pageSize,filters:{...storedFilters,...{sort:sort}}});        
      setListItems(resultList);        
      setTotalPages(total);
      dispatch(saveLists({ section : section, data : resultList}));
      return;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [currentPage,pageSize,storedFilters,sortBy,orderBy]); 


  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      setSortBy(sorter.columnKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }    
  };
  
  const showDrawer = (record) =>{
    setDrawerRecord(record);
    setOpenDrawer(true);
  };

  const closeDrawer = async () =>{
    setOpenDrawer(false);
  };

  const Edit = (record) =>{
    navigate('/joboffer/form',{ state:record });
  };

  const Delete = async (record) =>{    
    if (window.confirm('Êtes-vous sur de vouloir effacer cette Annonce ?')) {
      const response = await DeleteJobOffer(record._id);
      if (response) {
        const newList = listItems.filter((ele)=>{return ele._id !== record._id})      
        openNotification('success',"Annonce effacée");     
        setListItems(newList);
      } else {
        openNotification('error',"Un probléme est survenu lors de la suppression de l'annonce");     
      }
      return true;
    }
  }

  const onPaginationChange = (page,pageSize) => {  
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onLabelUpdated = (record) => {
    const updatedList = updateList(storedList,record); 
    setListItems(updatedList);                
    dispatch(saveLists({ section : section, data : updatedList}));
    return true;
  };

  //console.log(listItems);
  let columns = [
    // { 
    //   key: "language", 
    //   title: "Langue" ,
    //   dataIndex: "language", 
    //   width: '100px',
    //   fixed: 'left',    
    //   render: (record) => { 
    //     return ( 
    //     <> 
    //       <div>{record ? record.toUpperCase() : '-'}</div> 
    //     </> 
    //     ); 
    //   },        
    //   sorter: {},
    // },      
    { 
      key: "name", 
      title: "Nom", 
      dataIndex: "name", 
      sorter: {},
      width: '300px',
      fixed: 'left',      
      render: (value,record) => { 
          return ( 
          <> 
            <div className="custom-name capitalize link" onClick={() => Edit(record)} >{value}</div> 
          </> 
          ); 
        },      
      }, 
      { 
        key: "location", 
        title: "Lieu", 
        dataIndex: "location", 
        sorter: {}, 
      },    
      { 
        key: "sdate", 
        title: "Début" ,
        dataIndex: "sdate", 
        width: '120px',
        render: (record) => { 
          return ( 
          <> 
            <div>{moment(record).format('DD/MM/YYYY')}</div> 
          </> 
          ); 
        },        
        sorter: {},
      },  
      { 
        key: "edate", 
        title: "Fin" ,
        dataIndex: "edate", 
        width: '120px',
        render: (record) => { 
          return ( 
          <> 
            <div>{moment(record).format('DD/MM/YYYY')}</div> 
          </> 
          ); 
        },        
        sorter: {},
      },               
      { 
        key: "status", 
        title: "Status" ,
        dataIndex: "status", 
        width: '160px',
        render: (record) => { 
          return ( 
          <> 
            <div>{record === 1 ? 'Publié' : 'En attente'}</div> 
          </> 
          ); 
        },        
        sorter: {},
      },    
      { 
        key: "label", 
        title: "Labels",
        dataIndex: "label", 
        render: (value,record) => { 
          return(
            <LabelForm 
              section={section}
              labels={value}
              record={record}
              handlerMethod={onLabelUpdated}
              storedLabelList={storedLabelList || []}
          />
          );
        },      
      },           
      { 
        key: "action", 
        title: "Actions", 
        width: '120px',
        fixed: 'right',
        render: (record) => { 
          return ( 
            <> 
              <div className="flex" style={{textAlign:'right'}}>
                {record.users && record.users.length !== 0 &&                
                  <TeamOutlined 
                    title="Voir les postulants"
                    className='icon-table'
                    onClick={() => showDrawer(record)} 
                  /> 
                }
                <EditOutlined 
                  title="Editer"
                  className='icon-table'
                  onClick={() => Edit(record)} 
                /> 
                <DeleteOutlined 
                  title="Effacer"
                  className='icon-table'
                  onClick={() => Delete(record)} 
                /> 
              </div> 
            </> 
          ); 
        }, 
      },                  
  ];
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => {
    //console.log(col)
    let cleanCol = {
      ...col,
      fixed: isMobile ? undefined : col.fixed,
    };
    if (isMobile && cleanCol.key === 'location') {
      cleanCol.width = '130px';
    }
    return(cleanCol);
  });
  
  return (
    <div className="container">
      <HeaderBar 
        actionContent={
          <div className="header-actions" style={{ marginLeft:"16px"}}>
            <div onClick={()=>navigate('/joboffer/form')} className="secondary-button" >+</div>
          </div>
        }
        hasFilters={true} 
        hasSearch={true} 
        hasSort={false} 
        section={section} 
        storedFilters={storedFilters} 
        setCurrentPage={setCurrentPage}
      />
      <div className="content">    
        <div>
          <Table 
            dataSource={listItems} 
            rowKey="_id"
            columns={columns} 
            pagination={false} 
            className="capitalize custom-checkbox custom-table norowselection"
            onChange={onTableChange} 
            scroll={{
              y: viewportHeight*0.7,
              x: viewportWidth*0.5,
            }}
          />
          <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"
          />
        </div> 
        <UserListDrawer 
          drawerLoading={true}
          openDrawer={openDrawer}
          handlerMethod={closeDrawer} 
          record={drawerRecord} 
        />
      </div>
    </div>
  );
};

export default JobOfferList ;
