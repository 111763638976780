import React from 'react';
import {  Upload } from 'antd';
import { DownloadOutlined, FileAddOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector,useDispatch } from 'react-redux';
import { useNotification } from '../../hooks/NotificationProvider';
import { saveClient } from '../../features/client/clientSlice';
import { saveOperation } from '../../features/operation/operationSlice';
import { saveLists } from '../../features/lists/listsSlice';
import { updateList } from "../../utils/miscHelpers"
import { downloadFile, UploadSectionFile, DeleteSectionFile,blobToDownload } from '../../services/File'

const FileUpload = ({section,record}) => {
    
    const dispatch = useDispatch();
    const { openNotification } = useNotification();    
    
    const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : null);    

    const showFile = async ( media ) => {
        try {
            const file = await downloadFile({section,...{file: media.file},...{size:''}});                      
            return blobToDownload(file,media.name);            
        } catch (error) {
            console.error('showFile',error);
        }
    };

    const addFile = async ({ file, onSuccess, onError }) => {
        try {            
            const entryUpdated = await UploadSectionFile(file, section, record._id); 
            if (entryUpdated && entryUpdated._id) {
                switch(section){
                    case 'client' :
                        dispatch(saveClient(entryUpdated));
                        break;
                    case 'operation' :
                        dispatch(saveOperation(entryUpdated));
                        break;                        
                    default:
                }                       
                const updatedList = updateList(storedList,entryUpdated) ;
                dispatch(saveLists({section:section,data:updatedList}));
                openNotification('success','Fichier Sauvegardé');
                onSuccess();
            } else {
                openNotification('error','Probléme lors de la sauvegarde');
                onError();
            }
        } catch (error) {
            console.error('addFile',error);
            onError();
        }
    };

    const deleteFile = async ( file ) => {
        try {         
            if (window.confirm('Êtes-vous sur de vouloir supprimer ce fichier ?')) {
                const entryUpdated = await DeleteSectionFile(file, section, record._id); 
                if (entryUpdated && entryUpdated._id) {                            
                    switch(section){
                        case 'client' :
                            dispatch(saveClient(entryUpdated));
                            break;
                        case 'operation' :
                            dispatch(saveOperation(entryUpdated));
                            break;                        
                        default:
                    }                           
                    const updatedList = updateList(storedList,entryUpdated) ;
                    dispatch(saveLists({section:section,data:updatedList}));
                    openNotification('success','Fichier Effacé');
                    
                } else {
                    openNotification('error','Probléme lors de la suppression du fichier');
                    
                }
            }              
        } catch (error) {
            console.error('deleteFile',error);
        }
    };

    return (
        <div>
            <div className='file-content'>
                    <div className='h2'>Fichiers : </div>           
                    <Upload 
                        defaultFileList={[]}
                        fileList={[]}
                        customRequest={addFile}
                        name='file'
                    >
                        <div className="link" title="Ajouter un fichier" ><FileAddOutlined style={{fontSize:'16px',color:'var(--accent-color)'}}/></div>
                    </Upload>                            
                </div>
                <div className='file-list'>
                    {(record && record.medias && record.medias.length !== 0) &&
                        record.medias.map((media,index)=>{
                            return(
                                <div key={index.toString()} style={{display:'flex',justifyContent: "space-between",alignItems: "center"}}>
                                    <span className='bold'>{media.name}</span>
                                    <div>
                                    <span style={{marginRight:"16px"}}  title="Télécharger ce fichier" className='h2 link bigger' onClick={()=>showFile(media)}><DownloadOutlined/></span>                                    
                                    <span className='h2 link big' title="Effacer ce fichier" onClick={()=>deleteFile(media.file)}><DeleteOutlined/></span>
                                    </div>
                                </div>
                            )
                    })
                    }
                </div>
        </div>
    );

};
export default FileUpload;