/* eslint-disable no-loop-func */
import React,{useEffect, useState} from "react";
import { useTranslation } from 'react-i18next';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate,useLocation } from "react-router-dom";
import { Tabs } from 'antd';
import {  EditOutlined } from '@ant-design/icons';
import { useNotification } from '../../../hooks/NotificationProvider';

import HeaderBar from "../../../components/layout/HeaderBar";
import PresentationTab from "../../../components/operation/tabs/PresentationTab"
import StaffTab from "../../../components/operation/tabs/StaffTab"
import CartTab from "../../../components/operation/tabs/CartTab"
import TimelineTab from "../../../components/operation/tabs/TimelineTab";
import RoleTab from "../../../components/operation/tabs/RoleTab";
import ContractTab from '../../../components/operation/tabs/ContractTab'

import PopOverForm from "../../../components/elements/PopOverForm"
import ToDoIcon from "../../../components/todo/ToDoIcon";

import StaffMissionSlotsModal from "../../../components/operation/elements/StaffMissionSlotsModal"
// import RoleDrawer from '../../../components/operation/elements/RoleDrawer'

import { saveOperation } from '../../../features/operation/operationSlice';
import { EditOpProps, GetOp } from '../../../services/Operation';
//import { DemandMission } from '../../../services/Mission';

import { ListUsers,EditUserProps } from "../../../services/User"
import { saveLists } from '../../../features/lists/listsSlice';
import { saveMessage } from '../../../features/message/messageSlice'; 
import { saveFilters } from '../../../features/filters/filtersSlice';
import { 
  updateList,
  LocalUpdateUserMission,
  FormatMissionSlotsByRoles,
  capitalize
 } from "../../../utils/miscHelpers"



import {  importBmgFilters } from "../../../utils/miscHelpers"

const section = 'operation';

const OpDetail = () => {
  
  const defaultPageSize = 50;

  const { openNotification } = useNotification();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const storedLabelList = useSelector(state => state.lists.value && state.lists.value['label'] ? state.lists.value['label'] : []);    
  //const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedUserFilters = useSelector(state => state.filters.value && state.filters.value['user'] ? state.filters.value['user'] : {});
  const storedOperation = useSelector(state => state.operation.value && state.operation.value ? state.operation.value  : {});
  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : []);  

  const [paramOpId, setParamOpId] = useState((new URLSearchParams(location.search)).get('opId') || null);

  const [loading, setLoading] = useState(false);
  const [tabActiveKey, setTabActiveKey] = useState(location.state?.activeKey || '1');
  const [bmgFilters, setBmgFilters] = useState(null); 
  
  const [openGeneral, setOpenGeneral] = useState(true);

  const [listUsers, setListUsers] = useState([]);
  const [staffList, setStaffList] = useState([]); // For RoleTab
  const [cartList, setCartList] = useState([]); // For cat
  const [selectedUsers, setSelectedUsers] = useState([]); // For StaffTab
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(50);
  const [sortBy, setSortBy] = useState('lastName');
  const [orderBy, setOrderBy] = useState(1);
  const [isStaff, setIsStaff] = useState(true);
  const [isCart, setIsCart] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);    
  //const [openDrawer, setOpenDrawer] = useState(false);

  // VIEW PORT DISPLAY SIZES
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  useEffect(() => {
    dispatch(saveMessage({user:null,list:[],users:[]}));
}, [tabActiveKey]); 

  useEffect(() => {
    const fetchBmgFilters = async () => {
        const bmgFiltersList = await importBmgFilters();
        setBmgFilters(bmgFiltersList);
    };
    fetchBmgFilters(); 
  }, []);

  useEffect(() => {

    const opGetFromUrlQuery = async (opId) =>{
      const queryResult = await GetOp(opId);
      if (queryResult && queryResult._id) {           
        dispatch(saveOperation(queryResult));          
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.has('opId')) {
          urlSearchParams.delete('opId');
          // window.history.replaceState({}, '', '/operation/detail');
        }
        setParamOpId(null);
      }
    }

    if (!paramOpId) {
      if (!storedOperation || !storedOperation._id) {
        navigate('/operation');
      } 
    } else {
      opGetFromUrlQuery(paramOpId);
    }   
  }, [storedOperation, paramOpId,navigate]);

  const checkUserFilters = () =>{

    let isStaffCheck = Object.keys(storedUserFilters).length < 3 && (!Object.keys(storedUserFilters).includes('term') || storedUserFilters.term === '' ) ? true : false;

    return isStaffCheck;
  };

  useEffect(() => {
    if (selectedUsers && selectedUsers.length) {
      showModal();
    }
  }, [selectedUsers]);  

  useEffect(() => {

    const fetchStaffData = async () => {
      try { 

        setLoading(true);
        let listStaff = [];
        if (storedOperation && storedOperation.missionSlots && storedOperation.missionSlots.length) {                
            for (const missionSlot of storedOperation.missionSlots) {
                if (missionSlot.users && missionSlot.users.length) {
                    for(const userId of missionSlot.users){
                        if (!listStaff.includes(userId)) listStaff.push(userId);
                    }
                };
            }
        }
        
        if (listStaff.length) {
            let sortItems= {};
            if (!storedUserFilters.sort) {
              sortItems.sort = {};
              sortItems.sort[sortBy] = orderBy;
            } else {
              sortItems.sort = storedUserFilters.sort;
            }
            
            // Get user list
            const { resultList } = await ListUsers({filters:{...{ users : listStaff }, ...sortItems}},'nomedia');
            
            // Add Mission Slots to User List
            if (storedOperation && storedOperation.missionSlots && resultList && resultList.length) {
              for(let user of resultList){
                user = LocalUpdateUserMission(user,storedOperation);
              }
            }
            setStaffList(resultList);
        }
        setLoading(false);
        return;
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    const fetchUserData = async () => {
      try {        
        setLoading(true);
        let sortItems= {};
        if (!storedUserFilters.sort) {
          sortItems.sort = {};
          sortItems.sort[sortBy] = orderBy;
        } else {
          sortItems.sort = storedUserFilters.sort;
        }

        // Get user list
        const {resultList, total} = await ListUsers({page:currentPage,pageSize:pageSize,filters:{...storedUserFilters,...sortItems}},'nomedia');
        
        // Add Mission Slots to User List
        if (storedOperation && storedOperation.missionSlots && resultList && resultList.length) {
          for(let user of resultList){
            user = LocalUpdateUserMission(user,storedOperation);
          }
        }

        //dispatch(saveLists({section:section,data:resultList}));  
        setListUsers(resultList);
        setTotalPages(total);
        setLoading(false);
        return;
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };


    const fetchCartData = async () => {
      try {

        if (!storedOperation.users || !storedOperation.users.length) {
          setCartList([]);
          setTotalPages(0);
        }
        
        setLoading(true);
        let sortItems= {};
        if (!storedUserFilters.sort) {
          sortItems.sort = {};
          sortItems.sort[sortBy] = orderBy;
        } else {
          sortItems.sort = storedUserFilters.sort;
        }

        // Process only users that are not in the staff
        let users = storedOperation.users; // [];

        if (users && users.length) {
          // Get user list
          const {resultList,total} = await ListUsers({filters:{...{ users : users }, ...sortItems}},'nomedia');
          
          // Add Mission Slots to User List
          if (storedOperation && storedOperation.missionSlots && resultList && resultList.length) {
            for(let user of resultList){
              user = LocalUpdateUserMission(user,storedOperation);
            }
          }

          setListUsers(resultList);
          setTotalPages(total);

        }
        setLoading(false);
        return;
      } catch (error) {
        console.error('Error fetching cart list:', error);
      }
    };

    fetchStaffData();

    if (checkUserFilters()) {
      fetchCartData();
      setIsCart(true);
    } else {
      fetchUserData(); 
      setIsCart(false);   
    }
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };    
  }, [storedOperation, currentPage,pageSize,storedUserFilters,sortBy,orderBy]);  // Add storedOperation here 

  const onPaginationChange = (page,pageSize) => {  
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {    
    if (extra.action === 'sort') {      
      setSortBy(sorter.columnKey === 'name' ? 'lastName' : sorter.columnKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }    
  };

  const onTabChange = (key) => {
    setTabActiveKey(key);
  };
    
  const showModal = () =>{
    setIsModalOpen(true);
  };

  const closeModal = async () =>{
      setIsModalOpen(false);
  };  

  const onPopChange = async (user,props)=>{
    try {
      let userUpdated = await EditUserProps(user,props);
      if (userUpdated && userUpdated._id) {
        userUpdated = LocalUpdateUserMission(userUpdated,storedOperation);
        setListUsers( updateList(listUsers,userUpdated) );
        openNotification('success','Modification Enregistrée');
      }  else {
        openNotification('error','Probléme lors de la sauvegarde');
      } 
    } catch (error) {
      console.error('Error onPopChange EditUserProps:', error);
      openNotification('error','Catch / Probléme lors de la sauvegarde');
    }     
  };

  const onLabelUpdated = (record) => {
    const userRecord = listUsers.find((ele)=>ele._id === record._id);
    if (userRecord) {
      const updatedList = updateList(listUsers,{...userRecord,...{label:record.label}}) ;
      setListUsers( updatedList );
      dispatch(saveLists({section:'user',data:updatedList}));

      const updatedStaffList = updateList(staffList,{...userRecord,...{label:record.label}}) ;
      setStaffList(updatedStaffList);
    }
    return true;
  };

  const onAffectUsers = async (staffList,selectedRoleStaffKeys) =>{

    const roles = FormatMissionSlotsByRoles(storedOperation && storedOperation.missionSlots ? storedOperation.missionSlots : []);

    //console.log('onAffectUsers selectedRoleStaffKeys',selectedRoleStaffKeys);

    const cleanMissionSlots = [];

    let missionsAllUsers = [];

    for (const roleKey in roles){
      if (roles[roleKey] && roles[roleKey].missionSlots && roles[roleKey].missionSlots.length) {            
        let idx = 0;
        for(const mission of roles[roleKey].missionSlots){    
          let storedMissionSlot = storedOperation.missionSlots.find((ele)=>ele._id === mission._id);
          if (!storedMissionSlot) continue;          
          if (!storedMissionSlot.users) storedMissionSlot = { ...storedMissionSlot, users: [] }; 

          let missionSlot = {...mission}; 
          let missionUsers = [...storedMissionSlot.users];
          let staffIdx = 0;
          for(const staff of staffList){
              const kkey = roleKey+'-'+idx+'-'+staffIdx;
              if (selectedRoleStaffKeys.includes(kkey)) {
                if (!missionUsers.includes(staff._id)) missionUsers.push(staff._id);
              } else {
                if (missionUsers.includes(staff._id)){
                  missionUsers = missionUsers.filter((ele)=>ele !== staff._id) 
                }
              }
              staffIdx++;
          }
          missionSlot.users = missionUsers;
          missionsAllUsers = [...missionsAllUsers,...missionUsers];
          cleanMissionSlots.push(missionSlot);
          idx++;
        }
      }        
    }

    // let users = [];
    // if (storedOperation && storedOperation.users) {
    //   for(const sUser of storedOperation.users){
    //     if (!missionsAllUsers.includes(sUser)) users.push(sUser);
    //   }
    // }

    const props = {
        missionSlots: cleanMissionSlots,
        //users 
    };
    
    console.log(' onAffectUsers props',props);

    // Save           
    const entryUpdated = await EditOpProps(storedOperation._id,props);
    if (entryUpdated && entryUpdated._id) {        
        openNotification('success',"Information Sauvegardée");        
        // Update Operation + Op List
        const updatedList = updateList(storedList,entryUpdated); 
        dispatch(saveLists({section:section,data:updatedList}));
        dispatch(saveOperation(entryUpdated));
        dispatch(saveFilters({section:'user',data:{}})); 
    } else {
        openNotification('error',"Un problème est survenu lors de la sauvegarde");     
    }

  };

  const showCart = async () =>{
    dispatch(saveFilters({section:'user',data:{}}));    
  };

  const onDeleteStaff = async (staff) =>{
    if (window.confirm("Êtes-vous sûr de vouloir supprimer "+capitalize(staff.firstName)+" "+capitalize(staff.lastName)+" du Staff de l'opération "+capitalize(storedOperation.name)+" ?")) {
      if (storedOperation && storedOperation.missionSlots && storedOperation.missionSlots.length) {
        const cleanMissionSlots = [];
        for(const missionSlot of storedOperation.missionSlots){
          if (missionSlot && missionSlot.users){
            const newUsers = missionSlot.users.filter((userId)=>userId !== staff._id);
            cleanMissionSlots.push({
              ...missionSlot,
              users : newUsers
            });
          }
        }

        const entryUpdated = await EditOpProps(storedOperation._id,{ missionSlots : cleanMissionSlots },'deleteStaff',staff._id);
        if (entryUpdated && entryUpdated._id) {
            openNotification('success',"Enregistrée");     
            const updatedList = updateList(storedList,entryUpdated) ;
            dispatch(saveLists({section:section,data:updatedList}));
            dispatch(saveOperation(entryUpdated));  
        } else {
            openNotification('error',"Un problème est survenu lors de la sauvegarde");     
        }  
        return true;
      }
    }
  };
  

  const saveCartUsers = async (newCartUsers) =>{
      
    let users = storedOperation && storedOperation.users && storedOperation.users.length ? [...storedOperation.users] : [];
    for(const sUser of newCartUsers){
      if (!users.includes(sUser._id)) users.push(sUser._id);
    }

    if (users && users.length) {
      // Save           
      const entryUpdated = await EditOpProps(storedOperation._id,{ users });
      if (entryUpdated && entryUpdated._id) {        
          openNotification('success',"Utilisateur(s) ajouté(s) au panier");        
          const updatedList = updateList(storedList,entryUpdated); 
          dispatch(saveLists({section:section,data:updatedList}));
          dispatch(saveOperation(entryUpdated));
          //dispatch(saveFilters({section:'user',data:{}}));    
      } else {
          openNotification('error',"Un problème est survenu lors de l'ajout au panier");     
      }   
    }

  };

  const onDeleteCart = async (staff) =>{
    if (window.confirm("Êtes-vous sûr de vouloir supprimer "+capitalize(staff.firstName)+" "+capitalize(staff.lastName)+" du Panier de l'opération "+capitalize(storedOperation.name)+" ?")) {
      let users = [];
      if (storedOperation && storedOperation.users && storedOperation.users.length) {
        users = storedOperation.users.filter((userId)=> userId!== staff._id);
      }
      const entryUpdated = await EditOpProps(storedOperation._id,{ users });
      if (entryUpdated && entryUpdated._id) {        
          openNotification('success',"Utilisateur supprimé au panier");        
          const updatedList = updateList(storedList,entryUpdated); 
          dispatch(saveLists({section:section,data:updatedList}));
          dispatch(saveOperation(entryUpdated));
          //dispatch(saveFilters({section:'user',data:{}}));    
      } else {
          openNotification('error',"Un problème est survenu lors de la suppression au panier");     
      }      
    }
  };

  const onEditTodo = (todoUpdated) =>{
    if (todoUpdated && todoUpdated._id) {    
        const eleModified = {...storedOperation, todo : todoUpdated};
        dispatch(saveOperation(eleModified));
        dispatch(saveLists({ section : section, data : updateList(storedList,eleModified) }));        
    }
  };

  const tabItems = [
    {
      key: '1',
      label: (
        <span>
          {t('operation.tabs.presentation')}
        </span>
      ),
      children: <PresentationTab 
                  section={section}
                  bmgFilters={bmgFilters}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}
                  staffList={staffList}
                />,
    },
    {
      key: '2',      
      label: (
        <span>
          {t('operation.tabs.timeline')}
        </span>
      ),
      children: <TimelineTab
                  section={section}
                  bmgFilters={bmgFilters}                
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}
                  storedOperation={storedOperation}
                  staffList={staffList}
                />,
    },  
   
    {
      key: '3',      
      label: (
        <span>
          {t('operation.tabs.cart')}
        </span>
      ),
      children: <CartTab 
                  section={section}  
                  bmgFilters={bmgFilters}
                  listUsers={listUsers}
                  staffList={staffList}
                  cartList={cartList}
                  showCart={showCart}
                  loading={loading}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalPages={totalPages}
                  storedLabelList={storedLabelList}                  
                  onPaginationChange={onPaginationChange}
                  onTableChange={onTableChange}
                  onPopChange={onPopChange}    
                  onLabelUpdated={onLabelUpdated}    
                  onAffectUsers={onAffectUsers}
                  isCart={isCart}
                  saveCartUsers={saveCartUsers}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  showModal={showModal}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}    
                  onDeleteCart={onDeleteCart}        
                />,
    },       
    {
      key: '4',      
      label: (
        <span>
          {t('operation.tabs.staff')}
        </span>
      ),
      children: <StaffTab 
                  section={section}  
                  bmgFilters={bmgFilters}
                  staffList={staffList}
                  cartList={cartList}
                  loading={loading}
                  currentPage={currentPage}
                  pageSize={pageSize}
                  totalPages={totalPages}
                  storedLabelList={storedLabelList}                  
                  onPaginationChange={onPaginationChange}
                  onTableChange={onTableChange}
                  onPopChange={onPopChange}    
                  onLabelUpdated={onLabelUpdated}    
                  onAffectUsers={onAffectUsers}
                  isStaff={isStaff}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  showModal={showModal}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}    
                  onDeleteStaff={onDeleteStaff}        
                />,
    },
    {
      key: '5',
      label: (
        <span>
          {t('operation.tabs.role')}
        </span>
      ),
      children: <RoleTab 
                  section={section} 
                  bmgFilters={bmgFilters}
                  staffList={staffList}
                  onPopChange={onPopChange}    
                  onLabelUpdated={onLabelUpdated}
                  onAffectUsers={onAffectUsers}
                  showModal={showModal}
                  storedOperation={storedOperation}
                  openGeneral={openGeneral}
                  setOpenGeneral={setOpenGeneral}
                />,
      },
      {
        key: '6',
        label: (
          <span>
            {t('operation.tabs.contract')}
          </span>
        ),
        children: <ContractTab 
                    section={section}  
                    bmgFilters={bmgFilters}
                    staffList={staffList}
                    loading={loading}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    totalPages={totalPages}
                    storedLabelList={storedLabelList}                  
                    onPaginationChange={onPaginationChange}
                    onTableChange={onTableChange}
                    onPopChange={onPopChange}    
                    onLabelUpdated={onLabelUpdated}    
                    onAffectUsers={onAffectUsers}
                    isStaff={true}
                    showModal={showModal}
                    openGeneral={openGeneral}
                    setOpenGeneral={setOpenGeneral}  
                />,
        },
  ];
  
  return (
    <div className="container">
      <HeaderBar 
        actionContent={
          <div className="header-actions" style={{ marginLeft:"16px"}}>
            <div title="Créer une opération" onClick={()=>navigate('/operation/form')} className="secondary-button" >
              <EditOutlined 
                    title="Editer l'Opération"
                    style={{ color: "var(--accent-color)"}}
                  /> 
            </div>
          </div>
        }
        todoContent= {
          <PopOverForm
              content={
                  <ToDoIcon section={section} record={storedOperation} styles={{marginLeft:'16px',fontSize:'1.618rem'}}/>
              }
              form="todo"
              handlerMethod={onEditTodo}
              data={storedOperation.todo}
              target='operation'              
              targetData={storedOperation}
          />  
        }
        hasBack={true} 
        hasFilters={tabActiveKey === '3'} 
        hasSearch={tabActiveKey === '3'} 
        hasSort={tabActiveKey === '3'} 
        section={tabActiveKey === '3' ? 'user' : section} 
        subSection='operation'
        storedFilters={storedUserFilters} 
        sorterItem={{sortBy,orderBy}}
        setCurrentPage={setCurrentPage}
      />
      <div className="content">
        <Tabs
          //style={{border:'solid'}}
          activeKey={tabActiveKey}
          onChange={onTabChange}
          type="card"
          items={tabItems}
        />
      </div>      
      <StaffMissionSlotsModal
            section={section}
            staffList={['4','5'].includes(tabActiveKey) ? staffList : selectedUsers} 
            storedOperation={storedOperation}
            onAffectUsers={onAffectUsers}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
        />              
    </div>
  );
};

export default OpDetail ;
