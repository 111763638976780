import { Post, ApiRoutes } from "./Api"
import { MediaUrl, getMedia } from "./File"
import { DefaultAvatar } from "../utils/constants";

export const ListUsers = async (data,type) => {
    try {
        let resultList = [];
        const res = await Post(ApiRoutes.userList, data);
        if (res && res.success && res.value) {        
            if (res.value && res.value.length) {
                for (let user of res.value ) {
                    if (!user.files) user.files = [];
                    if (!user.avatar) user.avatar = [];
                    if (user.medias) {
                        user.avatar = await MediaUrl(user.medias,'avatar','small');                         
                        if (type!=='nomedia') user.files = await MediaUrl(user.medias,'media','small') ;
                    }
                }
            }
            resultList = res.value;            
        }
        return { resultList , total: res.total};
    } catch (err) {
      console.error('Error ListUsers',err);
    }
};

export const GetUser = async (dataId) => {
    try {
        return await Post(ApiRoutes.userGet, {id:dataId});
    } catch (err) {
      console.error('GetUser',err);
    }
};

export const EditUserProps = async (user,props) =>{
    try {
        const res = await Post(ApiRoutes.userEdit, { id: user._id, ...props});
        if (res && res.success && res.value){
            if (res.value.medias) {
                res.value.avatar = await MediaUrl(user.medias,'avatar','small');                         
                res.value.files = await MediaUrl(user.medias,'media','small') ;
            }            
            return res.value;
        } else {
            alert('Error EditUserProps');
        }        
    } catch (err) {
      console.error('ChangeStatus',err);
    }
};

export const GetAvatar = async (user)=>{
    if (user && user.medias && user.medias.length) {
        const avatarArr = user.medias.find((media)=>{ return media.section === 'avatar'});
        if (avatarArr) {
            return await getMedia({...avatarArr,size:'small'});
        } 
    }
    return DefaultAvatar;
};