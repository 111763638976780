import React,{ useState} from "react";
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../../hooks/NotificationProvider';
import { useSelector,useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {  Input, Select } from 'antd';

import { saveLists } from '../../../features/lists/listsSlice';
import { saveUser } from '../../../features/user/userSlice';
import { EditUserProps } from "../../../services/User";
import { CountriesFr,BodySizes,levelHospitality,languageLevels } from "../../../utils/constants"
import { changeArrayToLabelValue, changeListToLabelValue, updateList } from "../../../utils/miscHelpers";

import HeaderBar from "../../../components/layout/HeaderBar";

const { TextArea } = Input;

const section = 'user';

const UserForm = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();  
  const { openNotification } = useNotification();

  const storedList = useSelector(state => state.lists.value && state.lists.value[section] ? state.lists.value[section] : null);
  const storedUser = useSelector(state => state.user.value && state.user.value ? state.user.value  : {});
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});

  const levelHospitalityItems = changeListToLabelValue(levelHospitality,'title','id'); 

  const [firstName, setFirstName] = useState(storedUser ? storedUser.firstName : '');
  const [lastName, setLastName] = useState(storedUser ? storedUser.lastName : '');
  const [email, setEmail] = useState(storedUser ? storedUser.email : '');
  const [address, setAddress] = useState(storedUser ? storedUser.address : '');
  const [zipcode, setZipcode] = useState(storedUser ? storedUser.zipcode : '');
  const [city, setCity] = useState(storedUser ? storedUser.city : '');
  const [country, setCountry] = useState(storedUser ? storedUser.country : '');
  const [nationality, setNationality] = useState(storedUser ? storedUser.nationality : '');
  const [phone, setPhone] = useState(storedUser ? storedUser.phone : '');
  const [bodyUp, setBodyUp] = useState(storedUser ? storedUser.bodyUp : '');
  const [bodyDown, setBodyDown] = useState(storedUser ? storedUser.bodyDown : '');
  const [height, setHeight] = useState(storedUser ? storedUser.height : '');
  const [shoeSize, setShoeSize] = useState(storedUser ? storedUser.shoeSize : '');
  const [job, setJob] = useState(storedUser ? storedUser.job : '');
  const [studiesDescription, setStudiesDescription] = useState(storedUser ? storedUser.studiesDescription : '');
  const [expertiseDescription, setExpertiseDescription] = useState(storedUser ? storedUser.expertiseDescription : '');
  const [avsNumber, setAvsNumber] = useState(storedUser ? storedUser.avsNumber : '');
  const [iban, setIban] = useState(storedUser ? storedUser.iban : '');
  const [levelHospitalityUser, setLevelHospitalityUser] = useState(storedUser && storedUser.levelHospitality ? storedUser.levelHospitality : '');
  const [languages, setLanguages] = useState(storedUser ? storedUser.languages : null);    
  
  
  const changeEntry = (entryType, e,index,field) =>{     
    switch(entryType){
      case 'languages' :
          let newLanguages = languages.map(ele => ({...ele }));
          newLanguages[index][field] = e;   
          return setLanguages(newLanguages);
      default:
    }
  };
  
  const onChangeData = (e,setField)=>{    
    const value = e?.target?.value;      
    switch(setField){
      case 'setFirstName' :
        return  setFirstName(value); 
      case 'setLastName' :
        return  setLastName(value); 
      case 'setEmail' :
        return  setEmail(value);         
      case 'setAddress' :
        return  setAddress(value); 
      case 'setZipcode' :
        return  setZipcode(value); 
      case 'setCity' :
        return  setCity(value);  
      case 'setCountry' :
        return  setCountry(e); 
      case 'setNationality' :
        return  setNationality(e);
      case 'setLevelHospitalityUser' :
        return  setLevelHospitalityUser(e);        
      case 'setPhone' :
        return  setPhone(value); 
      case 'setBodyUp' :
        return  setBodyUp(e); 
      case 'setBodyDown' :
        return  setBodyDown(e);  
      case 'setHeight' :
        return  setHeight(value); 
      case 'setShoeSize' :
        return  setShoeSize(value); 
      case 'setJob' :
        return  setJob(value);      
      case 'setStudiesDescription' :
        return  setStudiesDescription(value); 
      case 'setExpertiseDescription' :
        return  setExpertiseDescription(value); 
      case 'setAvsNumber' :
        return  setAvsNumber(value);                        
      case 'setIban' :
        return  setIban(value);     
      default:
    }
  };
  
  const onSubmit = async () =>{

    if (!firstName || !lastName) {
      openNotification('error','Les champs Prénom et Nom obligatoires');
    }
    
    const props = {
      firstName,
      lastName,
      //email,
      address,
      zipcode,
      city,
      country,
      nationality,
      phone,
      bodyUp,
      bodyDown,
      height:Number(height),
      shoeSize:Number(shoeSize),
      job,
      studiesDescription,
      expertiseDescription,
      avsNumber,
      iban,
      levelHospitality:Number(levelHospitalityUser),
      languages
    };

    const userUpdated = await EditUserProps(storedUser, props);
    if (userUpdated && userUpdated._id) {
        dispatch(saveUser(userUpdated));
        dispatch(saveLists({ section : section, data : updateList(storedList,userUpdated) }));
        openNotification('success','Modification Enregistrée');
        navigate('/user/detail');
    } else {
        openNotification('error','Probléme lors de la sauvegarde');
    }

  };
  
  return (
    <div className="container">
      <HeaderBar 
        hasBack={true} 
        hasFilters={false} 
        hasSearch={false} 
        hasSort={false} 
        section={section} 
        storedFilters={storedFilters} 
        setCurrentPage={1}/>
      <div className="content">
        <div className="page-card">
          <div className="tab-form-block">

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Prénom</span>    
                  <Input 
                      title='Prénom'
                      // defaultValue={quote}
                      value={firstName}
                      id="firstName"
                      name="firstName"
                      placeholder="Prénom" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setFirstName")}
                  />
              </div>  
            </div>

            <div className='tab-form-block-column' style={{margin:'0 16px'}}>
              <div className="form-label-name">
                <span className="label">Nom</span>    
                  <Input 
                      title='Nom'
                      // defaultValue={quote}
                      value={lastName}
                      id="lastName"
                      name="lastName"
                      placeholder="Nom" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setLastName")}
                  />
              </div>  
            </div>  

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Email</span>    
                  <Input 
                      title='Email'
                      // defaultValue={quote}
                      value={email}
                      id="email"
                      name="email"
                      placeholder="Email" 
                      className=""
                      disabled={true}
                      onChange={(e)=>onChangeData(e,"setEmail")}
                  />
              </div>  
            </div>  

          </div>

          <div className="tab-form-block">

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Adresse</span>    
                  <Input 
                      title='Adresse'
                      // defaultValue={quote}
                      value={address}
                      id="address"
                      name="address"
                      placeholder="Adresse" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setAddress")}
                  />
              </div>  
            </div>

            <div className='tab-form-block-column' style={{margin:'0 16px'}}>
              <div className="form-label-name">
                <span className="label">Code Postal</span>    
                  <Input 
                      title='Code Postal'
                      // defaultValue={quote}
                      value={zipcode}
                      id="zipcode"
                      name="zipcode"
                      placeholder="Code Postal" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setZipcode")}
                  />
              </div>  
            </div>   

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Ville</span>    
                  <Input 
                      title='Ville'
                      // defaultValue={quote}
                      value={city}
                      id="city"
                      name="city"
                      placeholder="Ville" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setCity")}
                  />
              </div>  
            </div>

          </div>

          <div className="tab-form-block">
            
            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Téléphone</span>    
                <Input 
                    title='Téléphone'
                    // defaultValue={quote}
                    value={phone}
                    id="phone"
                    name="phone"
                    placeholder="Téléphone" 
                    className=""
                    onChange={(e)=>onChangeData(e,"setPhone")}
                />
              </div>  
            </div> 

            <div className='tab-form-block-column' style={{margin:'0 16px'}}>
              <div className="form-label-name">
                <span className="label">Pays</span>    
                <Select
                  style={{
                    width: '100%',                      
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Pays"
                  defaultValue={''}
                  value={country}
                  onChange={(e)=>onChangeData(e,"setCountry")}
                  options={changeArrayToLabelValue(CountriesFr)}
                  className="capitalize-full filter-select"
                />                                
              </div>  
            </div>

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Nationalité</span>    
                  <Select
                    style={{
                      width: '100%',                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    placeholder="Nationalité"
                    defaultValue={''}
                    value={nationality}
                    onChange={(e)=>onChangeData(e,"setNationality")}
                    options={changeArrayToLabelValue(CountriesFr)}
                    className="capitalize-full filter-select"
                  />
              </div>  
            </div>
          </div> 


          <div className="tab-form-block">


            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Taille</span>    
                  <Input 
                      title='Taille'
                      value={height}
                      id="height"
                      name="height"
                      placeholder="Taille" 
                      className=""
                      type="number"
                      onChange={(e)=>onChangeData(e,"setHeight")}
                  />
              </div>  
            </div>

            <div className='tab-form-block-column' style={{margin:'0 16px'}}>
              <div className="form-label-name">
                <span className="label">Haut / Jacket</span>    
                  <Select
                    style={{
                      width: '100%',                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    title='Haut / Jacket'
                    defaultValue={''}
                    value={bodyUp}
                    onChange={(e)=>onChangeData(e,"setBodyUp")}
                    options={BodySizes}
                    className="uppercase filter-select"
                  />    
              </div>  
            </div>

            <div className='tab-form-block-column' style={{margin:'0 16px'}}>
              <div className="form-label-name">
                <span className="label">Bas / Trouser</span>    
                  <Select
                    style={{
                      width: '100%',                      
                    }}
                    optionLabelProp="label"
                    optionFilterProp="label"
                    title='Bas / Trouser'
                    defaultValue={''}
                    value={bodyDown}
                    onChange={(e)=>onChangeData(e,"setBodyDown")}
                    options={BodySizes}
                    className="uppercase filter-select"
                  />    
              </div>  
            </div>   

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Pointure</span>    
                  <Input 
                      title='Pointure'
                      value={shoeSize}
                      id="shoeSize"
                      name="shoeSize"
                      placeholder="Pointure" 
                      className=""
                      type="number"
                      onChange={(e)=>onChangeData(e,"setShoeSize")}
                  />
              </div>  
            </div>

          </div>

          <div className="tab-form-block">

            <div className='tab-form-block-column' style={{marginRight:'16px'}}>
              <div className="form-label-name">
                <span className="label">Activité</span>    
                  <TextArea 
                      title='Activité'
                      // defaultValue={quote}
                      value={job}
                      id="job"
                      name="job"
                      placeholder="Activité" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setJob")}
                  />
              </div>  
            </div>

            <div className="form-label-name">
                <span className="label">Déscription</span>    
                  <TextArea 
                      title='Déscription'
                      // defaultValue={quote}
                      value={expertiseDescription}
                      id="expertiseDescription"
                      name="expertiseDescription"
                      placeholder="Déscription" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setExpertiseDescription")}
                  />
              </div>  
            
          </div>

          <div className="tab-form-block">

              <div className="form-label-name">
                <span className="label">Experience</span>    
                  <TextArea 
                      title='Experience'
                      // defaultValue={quote}
                      value={studiesDescription}
                      id="studiesDescription"
                      name="studiesDescription"
                      placeholder="Experience" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setStudiesDescription")}
                  />              
            </div>
          </div>

          <div className="tab-form-block">

            <div className='tab-form-block-column' style={{marginRight:'16px'}}>
              <div className="form-label-name">
                <span className="label">AVS</span>    
                  <Input 
                      title='AVS'
                      // defaultValue={quote}
                      value={avsNumber}
                      id="avsNumber"
                      name="avsNumber"
                      placeholder="AVS" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setAvsNumber")}
                  />
              </div>  
            </div>

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Iban</span>    
                  <Input 
                      title='Iban'
                      // defaultValue={quote}
                      value={iban}
                      id="iban"
                      name="iban"
                      placeholder="Iban" 
                      className=""
                      onChange={(e)=>onChangeData(e,"setIban")}
                  />
              </div>  
            </div>

          </div>
          <div className="tab-form-block">

            <div className='tab-form-block-column'>
              <div className="form-label-name">
                <span className="label">Nb. Missions</span>    
                <Select
                  style={{
                    width: '100%',                      
                  }}
                  optionLabelProp="label"
                  optionFilterProp="label"
                  placeholder="Nb. Missions"
                  defaultValue={'1'}
                  value={levelHospitalityUser}
                  onChange={(e)=>onChangeData(e,"setLevelHospitalityUser")}
                  options={levelHospitalityItems}
                  className="capitalize-full filter-select"
                />                
              </div>  
            </div>

            <div className='tab-form-block-column'>
              <div className="form-label-name black" >
                <span className="label">Niveau des Langues</span>    
                {languages && languages.length>0 && languages.map((langObj,idx)=>{
                  return(
                    <div key={idx.toString()} className="flex flex-column" style={{paddingBottom:'10px'}}>
                      <div className="h3 capitalize">{langObj.name}</div>
                      <Select
                        style={{
                          width: '100px',                      
                        }}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        placeholder="Niveau"
                        // defaultValue={'1'}
                        value={langObj.level}                        
                        onChange={(e)=>changeEntry('languages', e,idx,'level')}
                        options={languageLevels}
                        className="capitalize-full filter-select"
                      />     
                    </div>  
                  )
                })}
              </div>  
            </div>

          </div>

          <div  className="flex flex-column-center" style={{width:'100%'}}> 
            <div className='button  button-secondary button-tiny spacer'  onClick={()=>navigate('/user/detail')} style={{marginTop:'16px'}}>                
                <span>{t('buttons.cancel')}</span>
            </div>            
            <div className='button button-with-accent button-tiny '  onClick={onSubmit} style={{marginTop:'16px'}}>                
                <span>Enregistrer</span>
            </div>
          </div>              

        </div>
      </div>
    </div>
  );
};

export default UserForm ;
