import React,{useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
//import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Badge, Table, Pagination} from 'antd';
import { useMediaQuery } from 'react-responsive';
import {DeleteOutlined,SendOutlined,EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

import { capitalize, getNotificationCount, formatUserListWithValueLabel } from "../../../utils/miscHelpers";
import HeaderBar from "../../../components/layout/HeaderBar";
import MessageDrawer from "../../../components/message/MessageDrawer";
import { saveMessage, resetMessage } from '../../../features/message/messageSlice';
import { saveNotification } from '../../../features/notification/notificationSlice';
import { saveLists } from '../../../features/lists/listsSlice';
import { saveUser } from '../../../features/user/userSlice';

import { ListUsers } from "../../../services/User"
import { ListMessages } from "../../../services/Message"
import { ListNotifications } from "../../../services/Notification"

const section = 'message';

const MessageList = () => {
  
  const defaultPageSize = 100;
  
  const storedFilters = useSelector(state => state.filters.value && state.filters.value[section] ? state.filters.value[section] : {});
  const storedMessageNotification = useSelector(state => state.notification.value && state.notification.value.message ? state.notification.value.message : []);

  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [listItems, setListItems] = useState([]);
  //const [searchTerm, setSearchTerm] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalPages, setTotalPages] = useState(100);
  //const [open, setOpen] = useState(false);    
  const [selectedUsers, setSelectedUsers] = useState([]);    
  
  const [sortBy, setSortBy] = useState('cdate');
  const [orderBy, setOrderBy] = useState(-1);


  // VIEW PORT DISPLAY SIZES
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const handleResize = () => {
    setViewportWidth(window.innerWidth);
    setViewportHeight(window.innerHeight);
  };

  const fetchData = async () => {
    try {        
      const sort= {};
      sort[sortBy] = orderBy;
      
      // Get list
      const {resultList, total} = await ListMessages({page:currentPage,pageSize:pageSize,term:'',filters:storedFilters,sort:sort});
      dispatch(saveLists({section:section,data:resultList}));
      setListItems(resultList);
      setTotalPages(total);      

      // Fetch Notification
      dispatch(saveNotification(await ListNotifications()));

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [currentPage,pageSize,storedFilters,sortBy,orderBy]); 

  const onTableChange = (pagination, filters, sorter, extra) => {
    if (extra.action === 'sort') {
      let colmunKey = sorter.columnKey;
      if (sorter.columnKey === 'senderName')  colmunKey = 'sender';
      if (sorter.columnKey === 'receiverName') colmunKey = 'receiver';
      setSortBy(colmunKey);
      setOrderBy(sorter.order === 'ascend' ? 1 : -1);
    }    
  };
  
  const linkMeToUser = async (user)=>{
    try {    
      const {resultList } = await ListUsers({page:currentPage,pageSize:pageSize,filters:{_user:user._id}});
      if (resultList && resultList[0]) {
        await dispatch(saveUser(resultList[0]));
        navigate("/user/detail");
      } else {
        console.log('Unknow User ',user);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const openMessenger = async (record) =>{
    if (record && record._user) {
      dispatch(saveMessage({user:record._user,list:[],users:[]}));
    } else {
      dispatch(saveMessage({user:null,list:[],users:selectedUsers}));
      //dispatch(resetMessage());
    }
    setOpenDrawer(true);
  };

  const closeMessenger = async () =>{
    setOpenDrawer(false);
    dispatch(resetMessage());
    await fetchData();
  };

  const Delete = (record) =>{
    console.log('Delete record',record);
  };

  
  // 1sr column checkboxes
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(formatUserListWithValueLabel(selectedRows));
      //console.log(`rowSelection selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
  };

  const onPaginationChange = (page,pageSize) => {  
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const onChangeListType = () =>{
    setCurrentPage(1);
    setPageSize(100);
  }
  
  // const getNotificationCount = (origin,record) =>{
  //   let count = 0;
  //   if (record && storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length) {
  //     switch(origin){
  //       case 'message' :
  //         if (record && record._id){
  //           const listNotif = storedMessageNotification.list.filter((notif)=>{return notif._id === record._id});
  //           if (listNotif && listNotif.length) {
  //             count = listNotif.length;
  //           }
  //         }
  //         break;
  //       case 'user' :
  //         if (record._user && record._user._id){
  //           const listNotif = storedMessageNotification.list.filter((notif)=>{return notif._user === record._user._id});
  //           if (listNotif && listNotif.length) {
  //             count = listNotif.length;
  //           }
  //         }               
  //         break;     
  //       default:
  //         break;     
  //     }
  //   }
  //   return count;
  // };

  // Default List Type
  let columns = [
    { 
      key: "cdate", 
      title: "Date" ,        
      dataIndex: "cdate", 
      sorter: {},
      fixed: 'left',      
      width: '200px', 
      render: (record) => {
        return ( 
        <> 
          <div >{moment(record).format("HH:mm - DD/MM/YYYY")}</div> 
        </> 
        ); 
      },           
    },    
    { 
      key: "senderName", 
      title: "Envoyé par", 
      dataIndex: "senderName", 
      sorter: {},        
      width: '200px', 
      render: (value,record) => {
        if (record.sender === '_user') {
          return ( 
            <> 
              <div className="link custom-name" onClick={()=>linkMeToUser(record._user)}>{value}</div> 
            </> 
          ); 
        } else {
          return ( 
            <> 
              <div className="custom-name">{value}</div> 
            </> 
          ); 
        }
      },      
    }, 
    { 
      key: "receiverName", 
      title: "Destinataires", 
      dataIndex: "receiverName", 
      sorter: {},
      width: '200px',     
      render: (value,record) => {
        if (record.receiver === '_user') {
          return ( 
            <> 
              <div className="link custom-name" onClick={()=>linkMeToUser(record._user)}>{value}</div> 
            </> 
          ); 
        } else {
          return ( 
            <> 
              <div className="custom-name">{value}</div> 
            </> 
          ); 
        }
      },     
    },      
    { 
      key: "body", 
      title: "Messages" ,
      dataIndex: "body", 
      sorter: {},
    },
    { 
      key: "action", 
      title: "Actions", 
      width: '84px',
      fixed: 'right',
      render: (record) => { 
        const userName = record && record._user ? record._user.firstName : '';
        const notificationCount = getNotificationCount(
          'message',
          storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length ? storedMessageNotification.list : [], 
          record
        );
        
        return ( 
          <> 
            <div className="flex"> 
              <Badge count={notificationCount}>
                <SendOutlined 
                  title={'Envoyer un message à ' + capitalize(userName)}
                  style={{ color: "var(--accent-color)", marginRight:'8px'}} 
                  onClick={() => openMessenger(record)} 
                />
              </Badge>
              <DeleteOutlined 
                title="Effacer ce message"
                style={{ color: "var(--accent-color)", marginLeft :'8px'}} 
                onClick={() => Delete(record)} 
              /> 
            </div> 
          </> 
        ); 
      }, 
    },                  
  ];
 
  if (storedFilters.listType === '_user') {    
    columns = [
      { 
        key: "lastCDate", 
        title: "Dates Messages" ,        
        dataIndex: "lastCDate", 
        sorter: {},
        fixed: 'left',      
        width: '200px', 
        render: (record) => {
          return ( 
          <> 
            <div >{moment(record).format("HH:mm - DD/MM/YYYY")}</div> 
          </> 
          ); 
        },           
      },    
      { 
        key: "_user", 
        title: "Hôtes/Hôtesses", 
        dataIndex: "_user", 
        sorter: {},        
        width: '200px', 
        render: (record) => {
          const userName = record  ? capitalize(record.firstName) +' '+ capitalize(record.lastName) : '';          
          return ( 
            <> 
              <div className="link custom-name" onClick={()=>linkMeToUser(record)}>{userName}</div> 
            </> 
          ); 
          },      
      }, 
      { 
        key: "messageCount", 
        title: "Nb. messages", 
        dataIndex: "messageCount", 
        sorter: {},
        width: '168px',     
        render: (record) => {
          return ( 
          <> 
            <div className="custom-name">{record}</div> 
          </> 
          ); 
        },      
      },      
      { 
        key: "lastBody", 
        title: "Derniers Messages" ,
        dataIndex: "lastBody", 
        sorter: {},
      },
      { 
        key: "action", 
        title: "Voir", 
        width: '70px',
        fixed: 'right',
        render: (record) => { 
          const userName = record && record._user ? record._user.firstName : '';
          const notificationCount = getNotificationCount(
            'user',
            storedMessageNotification && storedMessageNotification.list && storedMessageNotification.list.length ? storedMessageNotification.list : [], 
            record
          );
          return ( 
              <div className="center"> 
                <Badge count={notificationCount}>
                  <EyeOutlined 
                    title={'Voir les messages de ' + capitalize(userName)}
                    className='icon-table'
                    onClick={() => openMessenger(record)} 
                  />
                </Badge>
                {/* <DeleteOutlined 
                  title="Effacer ce message"
                  style={{ color: "var(--accent-color)", marginLeft :'8px'}} 
                  onClick={() => Delete(record)} 
                />  */}
              </div> 
          ); 
        }, 
      },  
    ];
  }

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    
  columns = columns.map(col => {
    //console.log(col)
    let cleanCol = {
      ...col,
      fixed: isMobile ? undefined : col.fixed,
    };
    if (isMobile && cleanCol.key === 'lastBody') {
      cleanCol.width = '200px';
    }
    return(cleanCol);
  });
  

  return (
    <div className="container">
      <HeaderBar 
        hasFilters={true} 
        actionContent={
            <div className="header-actions" style={{ marginLeft:"16px"}}>
              <div onClick={openMessenger} className="secondary-button" >+</div>
            </div>
        }
        actionMethod={onChangeListType}
        hasSort={true} 
        hasSearch={true}
        section={section}
        storedFilters={storedFilters}
        setCurrentPage={setCurrentPage}/>
      <div className="content">    
        <div>
          <Table 
            dataSource={listItems} 
            rowKey="_id"
            columns={columns} 
            pagination={false} 
            className="capitalize custom-checkbox custom-table"
            onChange={onTableChange} 
            scroll={{
              y: viewportHeight*0.7,
              x: viewportWidth*0.5,
            }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
          />
          <Pagination
            showSizeChanger={true} 
            current={currentPage}
            pageSize={pageSize}                
            total={totalPages} 
            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}       
            onChange={onPaginationChange}
            className="pagination-list"
          />
        </div> 
        <MessageDrawer drawerLoading={true} openDrawer={openDrawer} handlerMethod={closeMessenger} />
      </div>      
    </div>
  );
};

export default MessageList ;