import React from "react";
import { useLocation, useNavigate } from 'react-router-dom';

import HeaderBar from "../../../components/layout/HeaderBar";
import ClientForm from "../../../components/client/elements/ClientForm";

const section = 'client';

const ClientDetailForm = () => {

  const navigate = useNavigate();
  const location = useLocation();
  
  const client = location.state || null;

  const topSpacer = 50;

  const onCancel = () =>{
    if (client) {
      navigate('/client');
    } else {
      navigate('/client');
    }
  };

  const onSubmit = (clientObj) =>{    
    //console.log('onSubmit :>> ',clientObj);
    if (client) {
      navigate('/client');
    } else {
      navigate('/client');
    }    
  };
  console.log('client',client);
  return (
    <div className="container">
        <HeaderBar 
          actionContent={null}        
          hasBack={true} 
          hasFilters={false} 
          hasSort={false} 
          hasSearch={false} 
          section={section} 
          storedFilters={{}}
        />
            <div className='tab-form' style={{borderRadius:'10px',backgroundColor:'white',padding:'16px',marginTop:topSpacer+'px'}}>           
                <ClientForm 
                    scale='full'
                    setOpen={onCancel}
                    data={client} 
                    handlerMethod={onSubmit}
                  />
            </div> 
                 
      </div>            
    
  );
};

export default ClientDetailForm;